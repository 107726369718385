import { LocalApi, RemoteApi, ScormApi } from 'qollap-api';
import { API_URL } from './variables';

class CustomRemoteApi extends RemoteApi {
  setToken(token) {
    super.setToken(token);
    this.apiPromise = new Promise((resolve, reject) => {
      this.apiPromise.then((res) => {
        if (this.authenticated) {
          fetch(`${API_URL}/api/accounts/cookie/`, {
            credentials: 'include',
            headers: {
              Authorization: `JWT ${token}`,
            },
          })
            .then(() => resolve(res))
            .catch(reject);
        } else {
          resolve(res);
        }
      });
    });
  }

  async deleteActivity(id) {
    const api = await this.apiPromise;
    const response = await api.action(['timeline', 'activity', 'delete'], {
      id,
    });
    return response;
  }

  async pinActivity(id) {
    const api = await this.apiPromise;
    const response = await api.action(['timeline', 'activity', 'pin'], {
      id,
    });
    return response;
  }

  async unpinActivity(id) {
    const api = await this.apiPromise;
    const response = await api.action(['timeline', 'activity', 'unpin'], {
      id,
    });
    return response;
  }

  async fetchJourneys() {
    const api = await this.apiPromise;
    const response = await api.action(['lms', 'dashboard', 'list']);
    return response;
  }

  async fetchLTIParams(uuid, locale, return_url) {
    const api = await this.apiPromise;
    const response = await api.action(['lms', 'modules', 'lti'], {
      uuid,
      locale,
      return_url,
    });
    return response;
  }

  async patchUserData(payload) {
    const api = await this.apiPromise;
    const response = await api.action(
      ['accounts', 'me', 'update_account'],
      payload
    );
    return response;
  }
  fetchDiaries = async () => {
    const api = await this.apiPromise;
    const response = await api.action(['content', 'list'], {
      tags: 'diaries',
    });
    return response;
  };
  verifyEmail = async ({ payload }) => {
    const api = await this.apiPromise;
    const response = await api.action(
      ['accounts', 'me', 'verify_email'],
      payload
    );
    return response;
  };

  postModuleStart = async (uuid) => {
    const api = await this.apiPromise;
    const response = await api.action(['lms', 'modules', 'start'], {
      uuid,
    });
    return response;
  };

  submitContact = async (message, name, organization) => {
    const api = await this.apiPromise;
    const response = await api.action(
      ['customer_specific', 'rein', 'contact'],
      {
        message,
        name,
        organization,
      }
    );
    return response;
  };

  submitPEOnline = async ({ uuid, big_number, relation_number }) => {
    const api = await this.apiPromise;
    const response = await api.action(
      ['customer_specific', 'rein', 'pe_online', 'create'],
      {
        uuid,
        big_number,
        relation_number,
      }
    );
    return response;
  };

  async submitStatus({ message, attachments }) {
    const api = await getApi().apiPromise;
    const response = await api.action(['statuses', 'create'], {
      message,
      ...(attachments ? { attachments } : {}),
    });
    return response;
  }

  async updateStatus({ id, message, attachments }) {
    const api = await getApi().apiPromise;
    const response = await api.action(['statuses', 'partial_update'], {
      id,
      message,
      ...(attachments ? { attachments } : {}),
    });
    return response;
  }

  async fetchTimelineActivities() {
    const api = await getApi().apiPromise;
    const response = await api.action(['timeline', 'activity', 'list'], {
      limit: 999,
    });
    return response;
  }

  async fetchTimelineActivity(id) {
    const api = await getApi().apiPromise;
    const response = await api.action(['timeline', 'activity', 'read'], {
      id,
    });
    return response;
  }

  async submitComment(payload) {
    const api = await getApi().apiPromise;
    return await api.action(['timeline', 'activity', 'comment'], payload);
  }

  async like(id) {
    const api = await getApi().apiPromise;
    const response = await api.action(
      ['timeline', 'activity', 'like', 'create'],
      { id }
    );
    return response;
  }

  async unlike(id) {
    const api = await getApi().apiPromise;
    const response = await api.action(
      ['timeline', 'activity', 'like', 'delete'],
      { id }
    );
    return response;
  }

  async deleteComment(id) {
    const api = await getApi().apiPromise;
    const response = await api.action(['timeline', 'comment', 'delete'], {
      id,
    });
    return response;
  }

  async likeComment(id) {
    const api = await getApi().apiPromise;
    const response = await api.action(
      ['timeline', 'comment', 'like', 'create'],
      { id }
    );
    return response;
  }
  async unlikeComment(id) {
    const api = await getApi().apiPromise;
    const response = await api.action(
      ['timeline', 'comment', 'like', 'delete'],
      { id }
    );
    return response;
  }

  async rapportageStatus() {
    const api = await getApi().apiPromise;
    const response = await api.action([
      'customer_specific',
      'rein',
      'report_status',
    ]);
    return response;
  }

  async requestCookie() {
    const api = await getApi().apiPromise;
    const response = await api.action(['accounts', 'cookie', 'list']);
    return response;
  }

  async validateBigRegistration(big_number) {
    const api = await getApi().apiPromise;
    // /api/customer_specific/rein/39061572517/check_big_number/
    const response = await api.action(
      ['customer_specific', 'rein', 'check_big_number'],
      { uuid: big_number }
    );
    return response;
  }

  async postLoginSSOJWT( data ) {
    const api = await getApi().apiPromise;
    try {
      const response = await api.action(['accounts', 'login', 'sso', 'jwt', 'create'], data);
      return response;
    } catch (er) {
      if (er.content && er.content.non_field_errors) {
        window.alert(er.content.non_field_errors.join(''));
      } else {
        throw er;
      }
    }
  }
}

const options = {
  api: new LocalApi(),
};

function setApi(schemaUrl) {
  options.api = new CustomRemoteApi(schemaUrl);
}

function setScormApi(fetchContent = true) {
  options.api = new ScormApi(fetchContent);
}

function getApi() {
  return options.api;
}

export { setApi, setScormApi, getApi };
