import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.css';
import { translate } from '../../utils';

class FormField extends React.Component {
  render() {
    const {
      className,
      type,
      label,
      min,
      minLength,
      max,
      maxLength,
      required,
      pattern,
      placeholder,
      name,
      onChange,
      errors,
      value,
      title,
      inputref
    } = this.props;
    const errorMessage = errors && errors[name] && errors[name][0];
    return (
      <>
        {errorMessage && (
          <p className="c-form-field__error" dangerouslySetInnerHTML={{ __html: translate(errorMessage) }} />
        )}
        <label
          className={classNames({
            'c-form-field': true,
            'c-form-field--invalid': errorMessage,
            [className]: className
          })}
        >
          <span className="c-form-field__label">{label}</span>
          <input
            className="c-form-field__input"
            autoFocus
            required={required}
            pattern={pattern}
            min={min}
            max={max}
            minLength={minLength}
            maxLength={maxLength}
            type={type}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            value={value}
            title={title}
            onBlur={e => e.target.value=e.target.value.trim()}
            ref={inputref}
          />
        </label>
      </>
    );
  }
}

FormField.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  value: PropTypes.string,
  inputref: PropTypes.object
};

export default FormField;
