import * as TYPES from './types';

const sortSticky = (a, b) => {
  return a.sticky === b.sticky ? 0 : a.sticky ? -1 : 1;
};

const sortCreatedDesc = (a, b) => {
  const dateA = Date.parse(a.created_at);
  const dateB = Date.parse(b.created_at);
  return dateA === dateB ? 0 : dateA < dateB ? 1 : -1;
};

const timeline = (state = { activities: [] }, { type, payload, id }) => {
  switch (type) {
    case TYPES.PIN_ACTIVITY:
      return {
        ...state,
        activities: state.activities
          .map(activity => {
            return activity.id === id
              ? {
                  ...activity,
                  sticky: true
                }
              : activity;
          })
          .sort(sortCreatedDesc)
          .sort(sortSticky)
      };
    case TYPES.UNPIN_ACTIVITY:
      return {
        ...state,
        activities: state.activities
          .map(activity => {
            return activity.id === id
              ? {
                  ...activity,
                  sticky: false
                }
              : activity;
          })
          .sort(sortCreatedDesc)
          .sort(sortSticky)
      };
    case TYPES.DELETE_ACTIVITY_SUCCESS:
      return {
        ...state,
        activities: state.activities.filter(activity => activity.id !== id)
      };
    case TYPES.FETCH_ACTIVITIES_SUCCESS:
      return {
        ...payload,
        activities: payload.results.filter(
          activity => activity.content_type == 'status'
        )
      };
    case TYPES.UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        activities: []
      };
    case TYPES.SUBMIT_COMMENT_SUCCESS:
    case TYPES.SUBMIT_STATUS_SUCCESS:
    case TYPES.LIKE_SUCCESS:
    case TYPES.UNLIKE_SUCCESS:
      return {
        ...state,
        activities: state.activities.map(activity =>
          activity.id == payload.id ? payload : activity
        )
      };
    case TYPES.LIKE_COMMENT_SUCCESS:
    case TYPES.UNLIKE_COMMENT_SUCCESS:
      return {
        ...state,
        activities: state.activities.map(activity =>
          activity.comments.find(({ id }) => id == payload.id)
            ? {
                ...activity,
                comments: activity.comments.map(comment =>
                  comment.id == payload.id ? payload : comment
                )
              }
            : activity
        )
      };
  }

  return state;
};

const timeline_processing = (state = {}, action) => {
  switch (action.type) {
    case TYPES.SUBMIT_COMMENT:
      return { ...state, commenting: true };
    case TYPES.SUBMIT_COMMENT_SUCCESS:
    case TYPES.SUBMIT_COMMENT_FAILED:
      return { ...state, commenting: false };
    case TYPES.SUBMIT_STATUS:
    case TYPES.UPDATE_STATUS:
      return { ...state, status: true };
    case TYPES.SUBMIT_STATUS_SUCCESS:
    case TYPES.SUBMIT_STATUS_FAILED:
    case TYPES.UPDATE_STATUS_SUCCESS:
    case TYPES.UPDATE_STATUS_FAILED:
      return { ...state, status: false };
  }

  return state;
};

export default {
  timeline,
  timeline_processing
};
