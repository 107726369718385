import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { translate } from '../../utils';
import { Logo } from '../../components/logo';
import './styles.css';
import { LXP_URL } from '../../variables';
import { get_studytube_login } from '../../utils/studytube';

class DashboardMenu extends React.Component {
  render() {
    const { className, onMenuItemHover, learnlinq, studytube } = this.props;

    return (
      <nav
        className={classNames({
          'c-dashboard-menu': true,
          [className]: className,
        })}
      >
        <Logo className="logo" size="190" />
        {learnlinq.enabled ? (
          <a
            href={`https://${learnlinq.name}.learnlinq.com/#!/learner`}
            className="c-dashboard-menu__link"
            data-identifier={'PROFILE'}
            onMouseEnter={(e) => {
              onMenuItemHover(e.currentTarget.dataset.identifier);
            }}
            onMouseLeave={() => {
              onMenuItemHover('');
            }}
          >
            <h3 className="c-dashboard-menu__text">
              {translate('LL_MIJN_LEREN')}
            </h3>
          </a>
        ) : studytube.enabled ? (
          <a
            href={get_studytube_login(studytube.name)}
            target="_blank"
            className="c-dashboard-menu__link"
            data-identifier={'PROFILE'}
            onMouseEnter={(e) => {
              onMenuItemHover(e.currentTarget.dataset.identifier);
            }}
            onMouseLeave={() => {
              onMenuItemHover('');
            }}
          >
            <h3 className="c-dashboard-menu__text">
              {translate('LL_MIJN_LEREN')}
            </h3>
          </a>
        ) : (
          <Link
            to={`/profile`}
            className="c-dashboard-menu__link"
            data-identifier={'PROFILE'}
            onMouseEnter={(e) => {
              onMenuItemHover(e.currentTarget.dataset.identifier);
            }}
            onMouseLeave={() => {
              onMenuItemHover('');
            }}
          >
            <h3 className="c-dashboard-menu__text">
              {translate('Jouw profiel')}
            </h3>
          </Link>
        )}

        {learnlinq.enabled ? (
          <a
            href={`https://${learnlinq.name}.learnlinq.com/#!/catalog`}
            className="c-dashboard-menu__link"
            data-identifier={'LIBRARY'}
            onMouseEnter={(e) => {
              onMenuItemHover(e.currentTarget.dataset.identifier);
            }}
            onMouseLeave={() => {
              onMenuItemHover('');
            }}
          >
            <h3 className="c-dashboard-menu__text">
              {translate('Online trainingen')}
            </h3>
          </a>
        ) : studytube.enabled ? (
          <a
            href={get_studytube_login(studytube.name, "/discover")}
            target="_blank"
            className="c-dashboard-menu__link"
            data-identifier={'LIBRARY'}
            onMouseEnter={(e) => {
              onMenuItemHover(e.currentTarget.dataset.identifier);
            }}
            onMouseLeave={() => {
              onMenuItemHover('');
            }}
          >
            <h3 className="c-dashboard-menu__text">
              {translate('Online trainingen')}
            </h3>
          </a>
        ) : (
          <Link
            to={`/library`}
            className="c-dashboard-menu__link"
            data-identifier={'LIBRARY'}
            onMouseEnter={(e) => {
              onMenuItemHover(e.currentTarget.dataset.identifier);
            }}
            onMouseLeave={() => {
              onMenuItemHover('');
            }}
          >
            <h3 className="c-dashboard-menu__text">
              {translate('Online trainingen')}
            </h3>
          </Link>
        )}
        <Link
          to={'#'}
          onClick={() => {
            window.location =
              LXP_URL +
              '/#/journey/ik-en-mijn-client/80b73b10-e51d-490c-a499-1d4a3f68f6bb' +
              '?jwt=' +
              window.localStorage.getItem('token');
          }}
          className="c-dashboard-menu__link"
          data-identifier={'BULLETIN_BOARD'}
          onMouseEnter={(e) => {
            onMenuItemHover(e.currentTarget.dataset.identifier);
          }}
          onMouseLeave={() => {
            onMenuItemHover('');
          }}
        >
          <h3 className="c-dashboard-menu__text">
            {translate('Persoonsgerichte zorg')}
          </h3>
        </Link>
        <Link
          to={'#'}
          onClick={() => {
            window.location =
              LXP_URL +
              '/#/journey/ik-en-mijn-team/b68eb824-0ff2-4a47-ac71-024e589d040e' +
              '?jwt=' +
              window.localStorage.getItem('token');
          }}
          className="c-dashboard-menu__link"
          data-identifier={'BULLETIN_BOARD'}
          onMouseEnter={(e) => {
            onMenuItemHover(e.currentTarget.dataset.identifier);
          }}
          onMouseLeave={() => {
            onMenuItemHover('');
          }}
        >
          <h3 className="c-dashboard-menu__text">
            {translate('Teamontwikkeling')}
          </h3>
        </Link>
        <Link
          to={'#'}
          onClick={() => {
            window.location =
              LXP_URL +
              '/#/journey/ik-en-mijn-talent/3de872fe-dcc7-488e-9968-e2f2ad03e2ee/' +
              '?jwt=' +
              window.localStorage.getItem('token');
          }}
          className="c-dashboard-menu__link"
          data-identifier={'BULLETIN_BOARD'}
          onMouseEnter={(e) => {
            onMenuItemHover(e.currentTarget.dataset.identifier);
          }}
          onMouseLeave={() => {
            onMenuItemHover('');
          }}
        >
          <h3 className="c-dashboard-menu__text">
            {translate('Talentanalyse')}
          </h3>
        </Link>
        <a
          onClick={() => {
            window.location =
              LXP_URL +
              '/#/module/c70690fb-583c-49d4-a495-22fe095c9009/1' +
              '?jwt=' +
              window.localStorage.getItem('token');
          }}
          className="c-dashboard-menu__link"
          data-identifier={'BULLETIN_BOARD'}
          onMouseEnter={(e) => {
            onMenuItemHover(e.currentTarget.dataset.identifier);
          }}
          onMouseLeave={() => {
            onMenuItemHover('');
          }}
        >
          <h3 className="c-dashboard-menu__text">
            {translate('Persoonlijk ontwikkel plan')}
          </h3>
        </a>
        <a
          onClick={() => {
            window.location =
              LXP_URL + '/#/module/ac3d1ceb-d66f-4d65-9b11-73f6f2c01e94/1?jwt=' + window.localStorage.getItem('token');
          }}
          className="c-dashboard-menu__link"
          data-identifier={'CHALLENGES'}
          onMouseEnter={(e) => {
            onMenuItemHover(e.currentTarget.dataset.identifier);
          }}
          onMouseLeave={() => {
            onMenuItemHover('');
          }}
        >
          <h3 className="c-dashboard-menu__text">
            {translate('MENU_CHALLENGES')}
          </h3>
        </a>
        <Link
          to={`/ask-me`}
          className="c-dashboard-menu__link"
          data-identifier={'ASK_ME'}
          onMouseEnter={(e) => {
            onMenuItemHover(e.currentTarget.dataset.identifier);
          }}
          onMouseLeave={() => {
            onMenuItemHover('');
          }}
        >
          <h3 className="c-dashboard-menu__text">
            {translate('Digitale assistent')}
          </h3>
        </Link>
        <Link
          to={`/timeline`}
          className="c-dashboard-menu__link"
          data-identifier={'TIMELINE'}
          onMouseEnter={(e) => {
            onMenuItemHover(e.currentTarget.dataset.identifier);
          }}
          onMouseLeave={() => {
            onMenuItemHover('');
          }}
        >
          <h3 className="c-dashboard-menu__text">{translate('REINplein')}</h3>
        </Link>
      </nav>
    );
  }
}

DashboardMenu.propTypes = {
  className: PropTypes.string,
  onMenuItemHover: PropTypes.func,
  learnlinq: PropTypes.any,
};

export default DashboardMenu;
