import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { userLogout } from '../../store/user/actions';
import { translate } from '../../utils';

import { Logo } from '../../components/logo';
import { IconArrowLeft, IconHamburger, IconCross } from '../icons/index';
import './styles.css';

class Header extends React.Component {
  state = {
    isMenuOpen: false
  };

  toggleMenu = () => {
    this.setState(prevState => {
      return {
        isMenuOpen: !prevState.isMenuOpen
      };
    });
  };

  render() {
    const { isMenuOpen } = this.state;
    const { isMenuHidden } = this.props;
    return (
      <div className="s-header">
        <header className="header">
          <div className="icon" onClick={() => this.props.history.goBack()}>
            <IconArrowLeft size={40} color="#6E6876" />
            <p>{translate('HEADER_BACK')}</p>
          </div>
          <Logo className="logo" />
          {!isMenuHidden && (
            <span className="icon" onClick={this.toggleMenu}>
              <IconHamburger size={40} color="#6E6876" />
            </span>
          )}
        </header>
        {!isMenuHidden && (
          <section>
            <div
              className={classNames({
                overlay: true,
                open: isMenuOpen,
                close: !isMenuOpen
              })}
              onClick={this.toggleMenu}
            />
            <div
              className={classNames({
                menu: true,
                open: isMenuOpen,
                close: !isMenuOpen
              })}
            >
              <span className="icon" onClick={this.toggleMenu}>
                <IconCross size={30} color="#555" />
              </span>
              <div className="content-container">
                <Link to="/profile">
                  <h2 className="item">{translate('MENU_PROFILE_TEXT')}</h2>
                </Link>
                <a
                href={translate('MENU_FACTSHEET_URL')}
                download
                target="_blank"
                rel="noopener noreferrer" >
                  <h2 className="item">{translate('MENU_FACTSHEET_LABEL')}</h2>
                </a>
                <h2
                  className="item"
                  onClick={() => {
                    this.props.userLogout();
                  }}
                >
                  {translate('MENU_LOGOUT_TEXT')}
                </h2>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}

Header.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ userLogout }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
