import React from 'react';
import { IconDownload } from '../../components/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Header from '../../components/header';

import './styles.css';
import { fetchDiaries } from '../../store/diaries/actions';

class DiaryDetails extends React.Component {
  componentDidMount() {
    if (!this.props.diariesFetched) {
      this.props.fetchDiaries();
    }
  }

  render() {
    const { diary, diariesFetched } = this.props;
    if (!diariesFetched) {
      return null;
    }

    const {
      content: { title, story, image, pdf },
      meta: { media_url }
    } = diary;
    return (
      <div>
        <section className="s-diary-details">
          <Header {...this.props} />
          <div className="responsive-container">
            <h1 className="title">{title}</h1>
            <img className="img" src={`${media_url}${image}`} />
            <div className="content-container">
              <h2>{title}</h2>
              <div dangerouslySetInnerHTML={{__html: story}}></div>
            </div>
            <a
              className="icon-container"
              href={`${media_url}${pdf}`}
              download
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="icon">
                <IconDownload size="18px" color="#fff" />
              </div>
            </a>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    diariesFetched: state.diaries.fetched,
    diary: state.diaries.results.find(
      ({ uuid }) => uuid == props.match.params.uid
    )
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchDiaries }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DiaryDetails);
