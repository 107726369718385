import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { translate, getDateAsKebabYmdString } from '../../utils';
import { getApi } from '../../api';
import { updateUserData, fetchUserData } from '../../store/user/actions';
import { submitPEOnline } from '../../store/library/actions';
import { IconArrowLeft, IconDownload } from '../../components/icons';
import { Logo } from '../../components/logo';
import Button from '../../components/button';
import { API_URL } from '../../variables';
import './styles.css';

class Profile extends React.Component {
  state = {
    profile: {},
    refusedSubmit: false,
    invalidFields: [],
    inputChanged: false,
    showSavedMessage: false,
    showInvalidFieldsMessage: false,
    showCertificates: false,
    validBig: null,
  };

  componentDidMount() {
    this.props.fetchUserData();
    const query = new URLSearchParams(this.props.location.search);
    this.returnUrl = query.get('return_url');
    this.returnHash = query.get('return_hash');
    const {big_number} = this.props;
    let hasValidBig = null;
    if(big_number && big_number.length === 11){
      hasValidBig =  this.handleBIGCheck(big_number);
    } else {
      hasValidBig = (big_number.length === 6 || big_number.toLowerCase() === 'x')
    }
    this.setState({validBig: hasValidBig})
  }
  async handleBIGCheck(big_number) {
    const response = await getApi().validateBigRegistration(big_number);
    const { valid } = response;
    return valid;
  }

  handleInput(event) {
    const { name, value } = event.target;
    const evtTarget = event.target;
    // console.debug('hi',name, value, value.length)
    if (value.length === 11 && name === 'big_number') {
      this.handleBIGCheck(value).then((isValid) => {
        evtTarget.setCustomValidity(isValid ? '' : translate('PROFILE_BIG_INCORRECT_ENTRY'));
        evtTarget.reportValidity();
        evtTarget.checkValidity();
        this.setState({validBig: isValid})
      });
    } else {
      if (name === 'big_number' && this.state.validBig !==null) {
        //maybe a bit redundant, but works
        this.setState({validBig: null})
      }
      if(value.length === 6 || value.toLowerCase() === 'x'){
        // clear invalid big field… it's always correct here
        evtTarget.setCustomValidity('');
        evtTarget.checkValidity();
      }
    }

    this.setState((prevState) => {
      let object = { ...prevState.profile };

      ['big_number', 'birthday', 'gender'].includes(name)
        ? (object.profile_flexible_field = { [name]: value })
        : (object[name] = value);
      // bit messy… but probably useful: BE can check for x and has_big_vvn_registration before sending a fix-your-big-reminder
      if(name==='big_number' && value.toLowerCase() !== 'x'){
        object.profile_flexible_field.has_big_vvn_registration = true
      }

      return {
        inputChanged: true,
        profile: object,
        invalidFields: prevState.invalidFields.filter(
          (field) => field !== name
        ),
      };
    });

    event.target.checkValidity();
  }

  handleInputBlur(e) {
    e.target.value = e.target.value.trim();
    this.handleInput(e);
  }

  handleInvalidInput(event) {
    const { name, value } = event.target;
    // console.debug('hii:', name, value, 'if:', this.state.invalidFields, value.match(/\b[xX]$|\b[0-9]{6}$|\b[0-9]{11}$/))
    if(!value.match(/\b[xX]$|\b[0-9]{6}$|\b[0-9]{11}$/)){
      this.setState((prevState) => ({
        invalidFields: [...prevState.invalidFields, name],
      }));
    }
  }

  handleImage(event) {
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ preview: reader.result });
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  submit() {
    const { invalidFields, preview, profile = {} } = this.state;
    this.setState(() => ({
      refusedSubmit: false,
      inputChanged: false,
    }));
    if (preview) {
      profile.image = preview;
    }
    if (invalidFields.length) {
      this.setState(() => ({
        refusedSubmit: true,
        showInvalidFieldsMessage: true,
        showSavedMessage: false,
      }));
      return;
    }
    this.props.updateUserData(profile);
    this.setState({
      showSavedMessage: true,
      showInvalidFieldsMessage: false,
    });
    if (this.returnUrl) {
      const url =
        this.returnUrl + (this.returnHash ? '#' + this.returnHash : '');
      this.props.history.replace(url);
    }
  }
  getUnregisteredModules () {
    const valid_big = this.props.big_number.match(/^[0-9]{11}$/);
    return this.props.library.modules.filter(mod => !!mod.completed_at && mod.translatable_content['register-code'] && valid_big && !mod.invite_extra.pe_online).map(mod =>
      mod.uuid)
  }
  registerMissing(){
    const unregistered = this.getUnregisteredModules();
    const valid_big = this.props.big_number.match(/^[0-9]{11}$/);
    if(unregistered.length && valid_big) {
      unregistered.forEach(uuid => this.props.submitPEOnline({ uuid, big_number: this.props.big_number }))
    }
  }
  render() {
    const { organisations = [], profile, saved = null, library, big_number } = this.props;
    const {
      preview,
      invalidFields,
      showSavedMessage,
      showInvalidFieldsMessage,
      showCertificates,
      validBig
    } = this.state;

    const unregistered_mod_uuids = this.getUnregisteredModules();
    return (
      <section className="s-profile">
        <div className="bg-img" />
        <button
          className="back-btn"
          onClick={() => this.props.history.goBack()}
        >
          <span>
            <IconArrowLeft color="#6E6876" size={50} />
          </span>
          <span>{translate('PROFILE_BACK')}</span>
        </button>
        <div className="logo">
          <Logo className="logo__svg" />
        </div>
        <div className="container">

          <div className="cert-switch">
            <button className={`c-button${showCertificates ?'':' showing'}`} onClick={() => this.setState({showCertificates: false})}>Profiel</button>
            <button className={`c-button${showCertificates ?' showing':''} certificates`} onClick={() => this.setState({showCertificates: true})}>Certificaten</button>
          </div>

          {!showCertificates && (<div>
          <div className="profile-image">
            {preview ? (
              <img className="profile-image__img" src={preview} />
            ) : (
              <img
                className="profile-image__img"
                src={profile && profile.image}
              />
            )}
            <span className="profile-image__btn">
              <input
                type="file"
                name="image"
                onChange={this.handleImage.bind(this)}
              />
              <span>
                {preview || (profile && profile.image)
                  ? translate('PROFILE_CHANGE_PHOTO_TEXT')
                  : translate('PROFILE_CHOOSE_PHOTO_TEXT')}
              </span>
            </span>
          </div>
          <div className="info">
            {showInvalidFieldsMessage && (
              <div className="info__message">
                {translate('PROFILE_STATUS_INVALID_FIELDS')}
              </div>
            )}
            {showSavedMessage && (
              <div className="info__message">
                {saved && translate('PROFILE_STATUS_PROFILE_SAVE_SUCCESS')}
                {!saved && translate('PROFILE_STATUS_PROFILE_SAVE_ERROR')}
              </div>
            )}
            <div className="info__item">
              <p className="info__label">
                {translate('PROFILE_FIRST_NAME_TEXT')}
              </p>
              <input
                className="info__field"
                type="text"
                name="first_name"
                required
                defaultValue={profile && profile.user.first_name}
                onChange={this.handleInput.bind(this)}
                onBlur={this.handleInputBlur.bind(this)}
                onInvalid={this.handleInvalidInput.bind(this)}
              />
              <abbr
                className="info__required"
                title={translate('PROFILE_REQUIRED_FIELD')}
                aria-label="required"
              >
                *
              </abbr>
              {invalidFields && invalidFields.includes('first_name') && (
                <small className="info__error">
                  {translate('PROFILE_FIRST_NAME_REQUIRED')}
                </small>
              )}
            </div>
            <div className="info__item">
              <p className="info__label">
                {translate('PROFILE_LAST_NAME_TEXT')}
              </p>
              <input
                className="info__field"
                type="text"
                name="last_name"
                required
                defaultValue={profile && profile.user.last_name}
                onChange={this.handleInput.bind(this)}
                onBlur={this.handleInputBlur.bind(this)}
                onInvalid={this.handleInvalidInput.bind(this)}
              />
              <abbr
                className="info__required"
                title={translate('PROFILE_REQUIRED_FIELD')}
                aria-label="required"
              >
                *
              </abbr>
              {invalidFields && invalidFields.includes('last_name') && (
                <small className="info__error">
                  {translate('PROFILE_LAST_NAME_REQUIRED')}
                </small>
              )}
            </div>
            <div className="info__item">
              <p className="info__label">
                {translate('PROFILE_DATE_OF_BIRTH_TEXT')}
              </p>
              <input
                className="info__field"
                type="date"
                name="birthday"
                required
                min="1900-01-01"
                max={getDateAsKebabYmdString()}
                defaultValue={
                  (profile &&
                    profile.profile_flexible_field &&
                    profile.profile_flexible_field.birthday) ||
                  '1990-09-09'
                }
                onChange={this.handleInput.bind(this)}
                onBlur={this.handleInputBlur.bind(this)}
                onInvalid={this.handleInvalidInput.bind(this)}
              />
              <abbr
                className="info__required"
                title={translate('PROFILE_REQUIRED_FIELD')}
                aria-label="required"
              >
                *
              </abbr>
              {invalidFields && invalidFields.includes('birthday') && (
                <small className="info__error">
                  {translate('PROFILE_BIRTHDATE_REQUIRED')}
                </small>
              )}
            </div>
            <div className="info__item">
              <p className="info__label">
                {translate('PROFILE_OCCUPATION_TEXT')}
              </p>
              <input
                className="info__field"
                type="text"
                name="subtitle"
                defaultValue={profile && profile.subtitle}
                onChange={this.handleInput.bind(this)}
                maxLength={100}
              />
            </div>
            <div className="info__item">
              <p className="info__label">{translate('PROFILE_COMPANY_TEXT')}</p>
              <select
                name="company"
                className="info__field"
                defaultValue={profile && profile.company}
                onChange={this.handleInput.bind(this)}
              >
                {organisations.map((name, i) => (
                  <option key={i}>{name}</option>
                ))}
              </select>
            </div>
            <div
              className={`info__item ${
                this.returnUrl == '/library' ? 'highlight' : ''
              }`}
            >
              <p className="info__label">
                {
                validBig===true? (<span className="bigflag">✅ </span>):validBig===false ? (<span className="bigflag">❌ </span>):''
              }{translate('PROFILE_BIG_NUMBER_TEXT')}
              </p>
              <input
                className="info__field"
                type="text"
                name="big_number"
                required
                pattern="[xX]|[0-9]{11}|[0-9]{6}"
                defaultValue={ big_number }
                onChange={this.handleInput.bind(this)}
                onBlur={this.handleInputBlur.bind(this)}
                onInvalid={this.handleInvalidInput.bind(this)}
              />
              <abbr
                className="info__required"
                title={translate('PROFILE_REQUIRED_FIELD')}
                aria-label="required"
              >
                *
              </abbr>
              {invalidFields && invalidFields.includes('big_number') && (
                <small className="info__error">
                  {translate('PROFILE_BIG_NUMBER_REQUIRED')}
                </small>
              )}
            </div>
            <div className="info__item">
              <p className="info__label">{translate('PROFILE_GENDER_TEXT')}</p>
              <select
                className="info__field"
                name="gender"
                defaultValue={profile && profile.gender}
                onChange={this.handleInput.bind(this)}
              >
                <option value="Female">{translate('Female')}</option>
                <option value="Male">{translate('Male')}</option>
              </select>
            </div>
            <div className="info__item">
              <p className="info__label">{translate('EMAIL_ADDRESS_TEXT')}</p>
              <p className="info__field">
                {profile && profile.user && profile.user.email}
              </p>
            </div>
            <p>
              <small>{translate('PROFILE_REQUIRED_MESSAGE')}</small>
            </p>
          </div>

          <Button
            className="submit-btn"
            text={
              this.returnUrl == '/library'
                ? translate('PROFILE_SAVE_AND_BACK')
                : translate('PROFILE_SAVE')
            }
            onClick={this.submit.bind(this)}
          />
          </div>)}
          {showCertificates && (<div className="certificates-list">
            {
            [...new Map(library.modules.filter(mod => !!mod.completed_at).map(item =>
              [item['uuid'], item])).values()]
            .map(({
                title,
                completed_at,
                uuid
              }, index) => (
                <div className="completed_module-item" key={`mod_${index}`}>
                  <div className="completed_module-item_title">
                  {title}{unregistered_mod_uuids.includes(uuid)? '*': ''}
                  </div>
                  <div className="completed_module-item_date">
                    {new Date(completed_at).toLocaleString('nl-NL', {year: 'numeric', month: 'long', day: 'numeric'})}
                  </div>
                  <a
                    className="completed_module-item_download icon"
                    href={`${API_URL}/api/customer_specific/rein/${uuid}/certificate/`}
                    download
                    rel="noopener noreferrer"
                  >
                    <IconDownload size="18" color="#78b4b3"/>
                  </a>
              </div>
              ))}
              { unregistered_mod_uuids.length > 0 && (<Button type="button" className="submit-missing-btn" text={`${translate('LIBRARY_SUBMIT_TO_REGISTER')} *`} onClick={this.registerMissing.bind(this)}/>) }
          </div>)}
        </div>
      </section>
    );
  }
}

Profile.propTypes = {
  profile: PropTypes.object,
  updateUserData: PropTypes.func,
  fetchUserData: PropTypes.func,
  location: PropTypes.any,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
    goBack: PropTypes.func,
  }),
  organisations: PropTypes.array,
  saved: PropTypes.bool,
  library: PropTypes.object,
  big_number: PropTypes.string,
  submitPEOnline: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    profile: state.user.data,
    big_number: (state.user.data.profile_flexible_field && state.user.data.profile_flexible_field.big_number) || 'x',
    saved: state.user.user_data_patched,
    organisations: state.app.organisations,
    library: state.library
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserData,
      fetchUserData,
      submitPEOnline,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
