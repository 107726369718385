import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchRapportageStatus } from '../../store/rapportage/actions';
import reinDesk from '../../assets/img/Rein_Illustratie-01.svg';
import './styles.css'

import { API_URL } from '../../variables';


class Rapportage extends React.PureComponent {
  constructor(props) {
    super(props);
    props.fetchRapportageStatus();
    this.state = {
      status_update_interval: null
    }
  }

  componentDidMount() {
    this.status_update_interval = setInterval(this.props.fetchRapportageStatus, 5000)
  }

  componentWillUnmount() {
    clearInterval(this.status_update_interval);
  }

  downloadRapportage = () => {
    window.location = API_URL + "/api/customer_specific/rein/report/";
  }

  render() {
    if (!this.props.status) {
      return (
        <div>Loading...</div>
      )
    }

    if (this.props.status.allowed) {
      let organisations = this.props.status.organisations.map(org => {
        return <li>{org}</li>
      })

      return (
        <div className="rapportage">
          <div className="helpertext">
            <p>Hallo {this.props.user.first_name},<br/> ik begrijp dat je de nieuwste rapportage komt ophalen over de bezoekers van:</p>
            <ul>{organisations}</ul>
          </div>
          <div className="main">
            <img className="rein-desk" src={reinDesk} />
            <div className="download">
              {this.props.status.busy ? <p>Ik verzamel de gegevens voor je.<br/> Wanneer de rapportage klaar staat kun je hem met de knop downloaden.<br/> Let op, dit kan even duren! Wees geduldig.</p> :
              <p>De gegevens zijn verzameld!<br/> Klik op de download knop om je rapportage te downloaden</p>}
              <button className="downloadButton" onClick={this.downloadRapportage} disabled={this.props.status.busy}>Download rapportage</button>
              {this.props.status.busy && <div className="report-loader active"></div>}
            </div>
          </div>
        </div>
      );
    }

    else {
      return (
        <div className="rapportage">
          <div className="helpertext">
            <p>Hallo {this.props.user.first_name},<br/> je hebt helaas niet de juiste rechten om rapportages op te halen.</p>
          </div>
          <div className="main">
            <img className="rein-desk" src={reinDesk}/>
          </div>
        </div>
      )
    }
  }
}


const mapStateToProps = (state, OwnProps) => ({
  status: state.rapportage.status,
  user: state.user.data.user
});

const mapDispatchToProps = (dispatch) => bindActionCreators({fetchRapportageStatus}, dispatch);


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Rapportage);