import React from 'react';
import Lti from '../../utils/lti';

class AskMe extends React.Component {
  onModuleStarted = () => {
    setTimeout(
      this.props.history.goBack, 1000
    )
  };

  render() {
    return (
      <Lti
        onStarted={this.onModuleStarted}
        uuid={'da9dc70aec48461a95c63cf36d171f39'}
        target="_blank"
      />
    );
  }
}

export default AskMe;
