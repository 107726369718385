import * as TYPES from './types';

export const setWalktroughStep = currentStep => ({
  type: TYPES.SET_WALKTHROUGH_STEP,
  currentStep
});

export const startWalktrough = () => ({
  type: TYPES.START_WALKTHROUGH
});

export const stopWalktrough = () => ({
  type: TYPES.STOP_WALKTHROUGH,
  payload: {
    walkthrough_done: true
  }
});
