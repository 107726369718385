import React from 'react';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { verifyEmail } from '../../store/portal/actions';
import { translate } from '../../utils';

class EmailVerify extends React.Component {
  state = {
    status: 'loading'
  };

  componentDidMount() {
    try {
      const params = this.props.location.search
        .substring(1)
        .split('&')
        .reduce((m, l) => {
          const [key, value] = l.split('=');
          return {
            ...m,
            [key]: value
          };
        }, {});
      this.props.verifyEmail(params);
      this.setState({ loading: 'verifying' });
    } catch (e) {}
  }

  renderMessage(message, errors) {
    return (
      <div className="content">
        <div dangerouslySetInnerHTML={{ __html: message }} />
      </div>
    );
  }

  render() {
    if (this.props.errors) {
      return this.renderMessage(
        translate('This verification link is not valid'),
        this.props.errors
      );
    }

    switch (this.state.status) {
      case 'loading':
        return this.renderMessage(translate('Loading'));
      case 'verifying':
        return this.renderMessage(translate('Verifying'));
    }
  }
}

const mapStateToProps = state => {
  return {
    errors: state.portal_errors.verifyEmail,
    processing: state.portal_processing.verifyEmail
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      verifyEmail
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailVerify);
