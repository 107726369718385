import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { IconInfo, IconLogout } from '../../components/icons';
import { userLogout } from '../../store/user/actions';
import { startWalktrough } from '../../store/dashboard/actions';
import walkthroughConfig from './walkthrough-config';
import MediaQuery from 'react-responsive';
import { translate } from '../../utils';
import { get_studytube_login } from '../../utils/studytube';

import './styles.css';
import DashboardSVG from './dashboard-svg';
import Walkthrough from './walkthrough';
import { Logo } from '../../components/logo';
import DashboardMenu from '../../components/dashboard_menu/index';
import LLMobileLibraryTile from '../../assets/img/ll-mobile-library.svg';
import LLMobileAccountTile from '../../assets/img/ll-mobile-account.svg';
import { LXP_URL } from '../../variables';

class Dashboard extends React.Component {
  state = { menuItemHoveredIdentifier: '' };

  componentDidMount() {
    if (!this.props.walkthroughFinished) {
      this.props.startWalktrough();
    }
  }
  goto = (url) => () => {
    url.startsWith('http')
      ? (window.open(url, "_blank"))
      : this.props.history.push(url);
  };
  onLogoutClick = () => {
    this.props.userLogout();
  };
  renderSVG(viewBox) {
    const {
      currentWalkthroughStep,
      isWalkthroughRunning,
      learnlinq,
      studytube,
    } = this.props;
    const { menuItemHoveredIdentifier } = this.state;
    return (
      <DashboardSVG
        learnlinq={learnlinq}
        studytube={studytube}
        viewBox={viewBox}
        className={classNames({
          'dashboard-svg': true,
          'is-walkthrough-running': isWalkthroughRunning,
        })}
        activeHighlight={
          isWalkthroughRunning
            ? walkthroughConfig[currentWalkthroughStep].identifier
            : menuItemHoveredIdentifier
        }
        gotoLibrary={
          learnlinq.enabled
            ? this.goto(`https://${learnlinq.name}.learnlinq.com/#!/catalog`)
            : studytube.enabled
              ? this.goto(get_studytube_login(studytube.name, "/discover"))
              : this.goto('library')
        }
        gotoBoard={this.goto(LXP_URL + '/#/?jwt=' + window.localStorage.getItem("token"))}
        gotoDiaries={this.goto('diaries')}
        gotoTimeline={this.goto('timeline')}
        gotoProfile={
          learnlinq.enabled
            ? this.goto(`https://${learnlinq.name}.learnlinq.com/#!/learner`)
            : studytube.enabled
              ? this.goto(get_studytube_login(studytube.name))
              : this.goto('profile')
        }
        gotoAskMe={this.goto('ask-me')}
      />
    );
  }
  render() {
    const { learnlinq, studytube } = this.props;
    return (
      <div>
        <MediaQuery query="(min-width: 1000px)">
          <section className={classNames({ 's-dashboard': true })}>
            <div className="icons-container">
              <a
                className="icon"
                href={translate('MENU_FACTSHEET_URL')}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconInfo size={12} color="#6D6875" />
                <span className="tooltiptext">{translate('FAQ')}</span>
              </a>
              <a className="icon" onClick={this.onLogoutClick}>
                <IconLogout color="#6D6875" />
                <span className="tooltiptext">{translate('Logout')}</span>
              </a>
            </div>
            <DashboardMenu
              learnlinq={learnlinq}
              studytube={studytube}
              onMenuItemHover={(identifier) =>
                this.setState({ menuItemHoveredIdentifier: identifier })
              }
            />
            <div className="s-content">
              <MediaQuery query="(max-width: 1224px)">
                {this.renderSVG({
                  left: 300,
                  top: 0,
                  width: 2712.34,
                  height: 1590.15,
                })}
              </MediaQuery>
              <MediaQuery query="(min-width: 1225px)">
                {this.renderSVG({
                  left: 0,
                  top: 0,
                  width: 3012.34,
                  height: 1590.15,
                })}
              </MediaQuery>
              <Walkthrough />
            </div>
          </section>
        </MediaQuery>
        <MediaQuery query="(max-width: 999px)">
          <section className="s-mobile-dashboard">
            <header className="header">
              <Logo className="logo" />
              <div className="icon-container">
                <a
                  className="icon"
                  href={translate('MENU_FACTSHEET_URL')}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconInfo size={12} color="#6D6875" />
                  <span className="tooltiptext">{translate('FAQ')}</span>
                </a>
                <a onClick={this.onLogoutClick}>
                  <IconLogout size={20} color="#6D6875" />
                  <span className="tooltiptext">{translate('Logout')}</span>
                </a>
              </div>
            </header>
            <ul className="flex-list">
              {(learnlinq.enabled || studytube.enabled) ? (
                <a
                  href={
                    learnlinq.enabled 
                    ? `https://${learnlinq.name}.learnlinq.com/#!/catalog`
                    : get_studytube_login(studytube.name, "/discover")
                  }
                  target="_blank"
                  className="item library"
                  style={{
                    backgroundImage: `url('${LLMobileLibraryTile}')`
                  }}
                />
              ) : (
                <Link to="library" className="item library" />
                
              )}
              <a href={LXP_URL + '/#/?jwt=' + window.localStorage.getItem("token")} className="item bulletin-board"></a>
              <Link to="ask-me" className="item ask-me" />
              <Link to="timeline" className="item community" />
              <Link to="diaries" className="item diaries" />
              {(learnlinq.enabled || studytube.enabled) ? (
                <a
                  href={
                    learnlinq.enabled 
                    ? `https://${learnlinq.name}.learnlinq.com/#!/learner`
                    : get_studytube_login(studytube.name)
                  }
                  target="_blank"
                  className="item account"
                  style={
                    learnlinq.enabled
                      ? { backgroundImage: `url('${LLMobileAccountTile}')` }
                      : {}
                  }
                ></a>
              ) : (
                <Link to="profile" className="item account" />
              )}
            </ul>
            <Walkthrough isMobile />
          </section>
        </MediaQuery>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const hasFlex = state.user.data.profile_flexible_field;
  return {
    learnlinq: state.user.learnlinq,
    studytube: state.user.studytube,
    isWalkthroughRunning: state.walkthrough.isRunning,
    currentWalkthroughStep: state.walkthrough.currentStep,
    walkthroughFinished:
      hasFlex && state.user.data.profile_flexible_field.walkthrough_done,
  };
};
Dashboard.propTypes = {
  currentWalkthroughStep: PropTypes.number,
  isWalkthroughRunning: PropTypes.bool,
  learnlinq: PropTypes.object,
  startWalktrough: PropTypes.func,
  walkthroughFinished: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  userLogout: PropTypes.func,
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ userLogout, startWalktrough }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
