import React from 'react';
import { getApi } from '../api';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { postModuleStart } from '../store/library/actions';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ postModuleStart }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class Lti extends React.Component {
    state = {};
    form = React.createRef();

    async componentDidMount() {
      this.setState({
        ...(await getApi().fetchLTIParams(
          this.props.uuid,
          'nl-NL',
          `${window.location.origin}/#/library/?return=${this.props.uuid}`
        ))
      });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (this.form.current && !this.state.submitted) {
        this.props.postModuleStart(this.props.uuid);
        this.form.current.submit();
        this.props.onStarted && this.props.onStarted();
      }
    }

    render() {
      if (!this.state.url) {
        return null;
      }

      return (
        <form
          method="POST"
          ref={this.form}
          id="lti_form"
          action={this.state.url}
          target={this.props.target}
        >
          {this.state.oauth_params.map(([key, value]) => (
            <input key={key} type="hidden" value={value} name={key} />
          ))}
          {this.state.lti_params.map(([key, value]) => (
            <input key={key} type="hidden" value={value} name={key} />
          ))}
        </form>
      );
    }
  }
);
