import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.css';
import FormErrors from './error';
import Button from '../button';

class Form extends React.Component {
  static propTypes = {
    buttonsComponent: PropTypes.node,
    buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    className: PropTypes.string,
    spinner: PropTypes.bool,
    errors: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    onSubmit: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired
  };
  onSubmit = e => {
    this.props.onSubmit();
    e.preventDefault();
    return false;
  };

  renderButtons() {
    return this.props.buttonsComponent ? (
      this.props.buttonsComponent
    ) : (
      <Button
        className={classNames({ form__btn: true })}
        text={this.props.buttonText}
        type="submit"
        disabled={this.props.processing}
      />
    );
  }

  render() {
    const { children, errors, className } = this.props;
    const formClassName = className;

    return (
      <>
        <form onSubmit={this.onSubmit} className={formClassName}>
          {children}
          {this.renderButtons()}
          <FormErrors errors={errors} />
        </form>
        <div
          className={classNames({
            loader: true,
            active: this.props.processing
          })}
        />
      </>
    );
  }
}

export default Form;
