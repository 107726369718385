export const FETCH_MODULES = 'FETCH_MODULES';
export const FETCH_MODULES_SUCCESS = 'FETCH_MODULES_SUCCESS';
export const FETCH_MODULES_FAILED = 'FETCH_MODULES_FAILED';

export const MODULE_START = 'MODULE_START';
export const POST_MODULE_START_FAILED = 'POST_MODULE_START_FAILED';
export const POST_MODULE_START_SUCCESS = 'POST_MODULE_START_SUCCESS';

export const USER_AUTHENTICATED_SUCCESS = 'USER_AUTHENTICATED_SUCCESS';

export const SUBMIT_PEONLINE = 'SUBMIT_PEONLINE';
export const SUBMIT_PEONLINE_SUCCESS = 'SUBMIT_PEONLINE_SUCCESS';
export const SUBMIT_PEONLINE_FAILED = 'SUBMIT_PEONLINE_FAILED';
