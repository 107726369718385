import { all, put } from 'redux-saga/effects';

import { getApi } from '../../api';
import * as TYPES from './types';


export function* fetchTranslations() {
  const api = getApi();
  try {
    const response = yield api.fetchTranslations();
    yield put({ type: TYPES.FETCH_TRANSLATIONS_SUCCESS, payload: response });
  } catch ({ message }) {
    yield put({ type: TYPES.FETCH_TRANSLATIONS_FAILED, payload: { message } });
  }
}

export default function* appSaga() {
  yield all([
    fetchTranslations(),
  ]);
}
