import { put, all, takeLatest, call } from 'redux-saga/effects';

import { getApi } from '../../api';
import * as TYPES from './types';
import { API_URL, SITE_URL } from '../../variables';

export function* checkAuthenticated() {
  const api = getApi();
  yield api.apiPromise;
  if (!api.authenticated) {
    yield put({ type: TYPES.USER_AUTHENTICATED_FAILED });
  } else {
    const nextUrl = localStorage.getItem('nextUrl');
    const nextUrlBackend = localStorage.getItem('nextUrlBackend');
    if (nextUrlBackend) {
      localStorage.removeItem('nextUrlBackend');
      window.location.href = `${API_URL}${nextUrlBackend}`;
    } else if (nextUrl) {
      localStorage.removeItem('nextUrl');
      window.location.href = `${API_URL}${nextUrl}`;
    }
    yield put({ type: TYPES.USER_AUTHENTICATED_SUCCESS });
  }
}

function* fetchUserData() {
  const api = getApi();
  try {
    const response = yield api.fetchUserData();
    yield put({ type: TYPES.FETCH_USER_DATA_SUCCESS, payload: response });
  } catch ({ message }) {
    yield put({ type: TYPES.FETCH_USER_DATA_FAILED, payload: { message } });
  }
}

function* patchUserData({ payload }) {
  const api = getApi();
  try {
    const response = yield api.patchUserData(payload);
    yield put({ type: TYPES.PATCH_USER_DATA_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: TYPES.PATCH_USER_DATA_FAILED, error });
  }
}

function* userLogout() {
  try {
    yield localStorage.removeItem('token');
    yield call(fetch, SITE_URL + '/auth/logout/', {
      redirect: 'manual',
      credentials: 'include',
    });
    yield put({ type: TYPES.USER_LOGOUT_SUCCESS });
  } catch ({ message }) {
    yield put({ type: TYPES.USER_LOGOUT_FAILED });
  }
}

export function* updateFlexibleField({ payload }) {
  const api = getApi();
  try {
    yield api.postFlexFields(payload);
    yield put({
      type: TYPES.FETCH_FLEXIBLE_FIELDS_SUCCESS,
      payload,
    });
  } catch ({ message }) {
    yield put({
      type: TYPES.FETCH_FLEXIBLE_FIELDS_FAILED,
      payload: { message },
    });
  }
}

export default function* userSaga() {
  yield all([
    checkAuthenticated(),
    takeLatest(TYPES.CHECK_AUTHENTICATED, checkAuthenticated),
    takeLatest(TYPES.USER_AUTHENTICATED_SUCCESS, fetchUserData),
    takeLatest(TYPES.FETCH_USER_DATA, fetchUserData),
    takeLatest(TYPES.PATCH_USER_DATA, patchUserData),
    takeLatest(TYPES.UPDATE_INTRO, updateFlexibleField),
    takeLatest(TYPES.USER_LOGOUT, userLogout),
  ]);
}
