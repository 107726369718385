export const UNLIKE_COMMENT = 'UNLIKE_COMMENT';
export const UNLIKE_COMMENT_FAILED = 'UNLIKE_COMMENT_FAILED';
export const UNLIKE_COMMENT_SUCCESS = 'UNLIKE_COMMENT_SUCCESS';
export const FETCH_ACTIVITIES = 'FETCH_ACTIVITIES';
export const FETCH_ACTIVITIES_SUCCESS = 'FETCH_ACTIVITIES_SUCCESS';
export const FETCH_ACTIVITIES_FAILED = 'FETCH_ACTIVITIES_FAILED';

export const FETCH_ACTIVITY = 'FETCH_ACTIVITY';
export const FETCH_ACTIVITY_SUCCESS = 'FETCH_ACTIVITY_SUCCESS';
export const FETCH_ACTIVITY_FAILED = 'FETCH_ACTIVITY_FAILED';

export const SUBMIT_COMMENT = 'SUBMIT_COMMENT';
export const SUBMIT_COMMENT_SUCCESS = 'SUBMIT_COMMENT_SUCCESS';
export const SUBMIT_COMMENT_FAILED = 'SUBMIT_COMMENT_FAILED';

export const LIKE = 'LIKE';
export const LIKE_SUCCESS = 'LIKE_SUCCESS';
export const LIKE_FAILED = 'LIKE_FAILED';

export const UNLIKE = 'UNLIKE';
export const UNLIKE_SUCCESS = 'UNLIKE_SUCCESS';
export const UNLIKE_FAILED = 'UNLIKE_FAILED';

export const UPDATE_STATUS = 'UPDATE_STATUS';
export const UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS';
export const UPDATE_STATUS_FAILED = 'UPDATE_STATUS_FAILED';

export const SUBMIT_STATUS = 'SUBMIT_STATUS';
export const SUBMIT_STATUS_SUCCESS = 'SUBMIT_STATUS_SUCCESS';
export const SUBMIT_STATUS_FAILED = 'SUBMIT_STATUS_FAILED';

export const DELETE_COMMENT = 'DELETE_COMMENT';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_FAILED = 'DELETE_COMMENT_FAILED';

export const LIKE_COMMENT = 'LIKE_COMMENT';
export const LIKE_COMMENT_SUCCESS = 'LIKE_COMMENT_SUCCESS';
export const LIKE_COMMENT_FAILED = 'LIKE_COMMENT_FAILED';

export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';
export const DELETE_ACTIVITY_SUCCESS = 'DELETE_ACTIVITY_SUCCESS';
export const DELETE_ACTIVITY_FAILED = 'DELETE_ACTIVITY_FAILED';

export const PIN_ACTIVITY = 'PIN_ACTIVITY';
export const PIN_ACTIVITY_SUCCESS = 'PIN_ACTIVITY_SUCCESS';
export const PIN_ACTIVITY_FAILED = 'PIN_ACTIVITY_FAILED';

export const UNPIN_ACTIVITY = 'UNPIN_ACTIVITY';
export const UNPIN_ACTIVITY_SUCCESS = 'UNPIN_ACTIVITY_SUCCESS';
export const UNPIN_ACTIVITY_FAILED = 'UNPIN_ACTIVITY_FAILED';
