import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route } from 'react-router-dom';

import { resetPassword } from '../../store/portal/actions';
import FormField from '../../components/form_field';
import Button from '../../components/button';
import { BackButton } from './back-btn';
import { translate } from '../../utils';

class ForgotPassword extends React.Component {
  state = {
    email: undefined
  };

  handleInput(event) {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  }

  submit() {
    const { email } = this.state;
    email && this.props.resetPassword(email);
  }

  render() {
    const {
      errors,
      processing,
      match: { path }
    } = this.props;
    return (
      <div className="content">
        <BackButton to="/portal/login/email" />
        <article className="form">
          <Route
            exact
            path={path}
            render={() => (
              <>
                <h2 className="form__label">
                  {translate('PASSWORD_FORGOT__TITLE')}
                </h2>
                <p
                  className="form__content"
                  dangerouslySetInnerHTML={{
                    __html: translate('PASSWORD_FORGOT__TEXT')
                  }}
                />
                <div className="input-group">
                  <FormField
                    errors={errors}
                    className="input-group__field"
                    type="e-mail"
                    required={true}
                    placeholder="E-mailadres"
                    name="email"
                    onChange={this.handleInput.bind(this)}
                  />
                  <Button
                    className="input-group__btn"
                    text={'Verstuur'}
                    onClick={this.submit.bind(this)}
                    disabled={processing}
                  />
                </div>
              </>
            )}
          />
          <Route
            exact
            path={`${path}/requested`}
            render={() => (
              <>
                <h2 className="form__label">
                  {translate('PASSWORD_FORGOT_REQUESTED__TITLE')}
                </h2>
                <p
                  className="form__content"
                  dangerouslySetInnerHTML={{
                    __html: translate('PASSWORD_FORGOT_REQUESTED__TEXT')
                  }}
                />
              </>
            )}
          />
          <Route
            exact
            path={`${path}/success`}
            render={() => (
              <>
                <h2 className="form__label">
                  {translate('PASSWORD_FORGOT_SUCCESS__TITLE')}
                </h2>
                <p
                  className="form__content"
                  dangerouslySetInnerHTML={{
                    __html: translate('PASSWORD_FORGOT_SUCCESS__TEXT')
                  }}
                />
              </>
            )}
          />
        </article>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    errors: state.portal_errors.resetPassword,
    processing: state.portal_processing.resetPassword
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetPassword
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);
