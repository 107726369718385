import React from 'react';

import { BackButton } from './back-btn';
import Form from '../../components/form/form';
import { getApi } from '../../api';
import { translate } from '../../utils';
import FormField from '../../components/form_field';

class Contact extends React.Component {
  state = {};
  submit = async () => {
    this.setState({ status: 'submitting' });
    const response = await getApi().submitContact(
      `${this.state.message} - Afzender ${this.state.email}`,
      this.state.name,
      this.state.organization
    );
    if (response.status == 'success') {
      this.setState({ status: 'submitted' });
    } else {
      this.setState({ status: 'error' });
    }
  };

  onMessageChange = (e) => {
    this.setState({ message: e.target.value });
  };

  onEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  onOrganizationChange = (e) => {
    this.setState({ organization: e.target.value });
  };

  onNameChange = (e) => {
    this.setState({ name: e.target.value });
  };

  render() {
    const { status } = this.state;
    return (
      <div className="content">
        <BackButton />
        <article className="form">
          <h2 className="form__label">{translate('CONTACT_TITLE')}</h2>
          <Form
            buttonDisabled={status == 'submitting'}
            buttonsComponent={
              status == 'submitted' ? (
                <p className="c-form__result-message c-form__result-message--success">
                  {translate('CONTACT_MESSAGE_SENT')}
                </p>
              ) : status == 'error' ? (
                <p className="c-form__result-message c-form__result-message--error">
                  {translate('CONTACT_MESSAGE_ERROR')}
                </p>
              ) : null
            }
            buttonText={translate('CONTACT_SEND')}
            onSubmit={this.submit}
          >
            <FormField
              className="form__field"
              onChange={this.onNameChange}
              required="true"
              placeholder={translate('CONTACT_NAME')}
              type="text"
            />
            <FormField
              className="form__field"
              onChange={this.onOrganizationChange}
              required="true"
              placeholder={translate('CONTACT_ORGANIZATION')}
              type="text"
            />
            <FormField
              className="form__field"
              onChange={this.onEmailChange}
              required="true"
              placeholder={translate('CONTACT_EMAIL')}
              type="email"
            />
            <textarea
              onChange={this.onMessageChange}
              required={true}
              className="form__textarea"
              placeholder={translate('CONTACT_TYPE_YOUR_MESSAGE_HERE')}
            />
          </Form>
        </article>
      </div>
    );
  }
}

export default Contact;
