export const CREATE_ACCOUNT_POST = 'CREATE_ACCOUNT_POST';
export const CREATE_ACCOUNT_POST_SUCCESS = 'CREATE_ACCOUNT_POST_SUCCESS';
export const CREATE_ACCOUNT_POST_FAILED = 'CREATE_ACCOUNT_POST_FAILED';

export const LOGIN_POST = 'LOGIN_POST';
export const LOGIN_POST_SUCCESS = 'LOGIN_POST_SUCCESS';
export const LOGIN_POST_FAILED = 'LOGIN_POST_FAILED';

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAILED = 'DELETE_ACCOUNT_FAILED';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

export const CONFIRM_PASSWORD = 'CONFIRM_PASSWORD';
export const CONFIRM_PASSWORD_SUCCESS = 'CONFIRM_PASSWORD_SUCCESS';
export const CONFIRM_PASSWORD_FAILED = 'CONFIRM_PASSWORD_FAILED';

export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILED = 'VERIFY_EMAIL_FAILED';
