import React from 'react';
import { Link } from 'react-router-dom';

import { IconArrowLeft } from '../../components/icons';

export const BackButton = ({ to = '/portal' }) => (
  <Link className="back-btn" to={to}>
    <span>
      <IconArrowLeft color="#78B4B3" size={22} />
    </span>
    <span>{'Terug'}</span>
  </Link>
);
