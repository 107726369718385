import React from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import { Logo } from '../../components/logo';
import tutorialBuddy from '../../assets/img/tutorial-buddy.svg';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setWalktroughStep,
  stopWalktrough,
} from '../../store/dashboard/actions';
import { fetchUserData } from '../../store/user/actions';
import { updateFirstVisit } from '../../store/user/actions';
import walkthroughConfig from './walkthrough-config';
import { translate } from '../../utils';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.css';

class Walkthrough extends React.Component {
  componentDidMount() {
    this.props.fetchUserData();
  }

  onClose = async () => {
    this.props.stopWalktrough();
  };

  onNextSlide = () => {
    this.slider.slickNext();
  };

  render() {
    const {
      isRunning,
      currentStep,
      isMobile,
      userFirstName,
      learnlinq,
      studytube,
    } = this.props;
    const steps = walkthroughConfig;
    const settings = {
      accessibility: false,
      adaptiveHeight: true,
      draggable: false,
      dots: false,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: currentStep,
      beforeChange: (current, next) => {
        this.props.setWalktroughStep(next);
      },
    };
    return (
      <div
        className={classNames({
          's-walkthrough': true,
          'is-top': isRunning && steps[currentStep].popupPosition === 'top',
          'is-bottom':
            isRunning && steps[currentStep].popupPosition === 'bottom',
        })}
      >
        {isRunning && (
          <div className="popup-container">
            <img className="tutorial-buddy" src={tutorialBuddy} />
            <div className="logo-container">
              <Logo />
            </div>
            <Slider ref={(c) => (this.slider = c)} {...settings}>
              {steps.map((step, index) => {
                const stepsll = ['LIBRARY', 'TIMELINE', 'PROFILE'];
                const ll =
                  (learnlinq.enabled || studytube.enabled) && stepsll.includes(step.identifier)
                    ? 'LL_'
                    : '';
                return (
                  <p key={index}>
                    {translate(
                      isMobile
                        ? `${ll}MOBILE_WALKTHROUGH_${step.identifier}`
                        : `${ll}WALKTHROUGH_${step.identifier}`,
                      { first_name: userFirstName },
                      true
                    )}
                  </p>
                );
              })}
            </Slider>
            <div className="buttons-container">
              <button
                className={classNames({
                  hidden: currentStep === steps.length - 1,
                })}
                onClick={this.onClose}
              >
                {translate('WALKTHROUGH_SKIP_BUTTON_TEXT')}
              </button>
              <button
                className="next"
                onClick={
                  currentStep !== steps.length - 1
                    ? this.onNextSlide
                    : this.onClose
                }
              >
                {currentStep !== steps.length - 1
                  ? translate('WALKTHROUGH_NEXT_BUTTON_TEXT')
                  : translate('WALKTHROUGH_CLOSE_BUTTON_TEXT')}
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    learnlinq: state.user.learnlinq,
    studytube: state.user.studytube,
    isRunning: state.walkthrough.isRunning,
    currentStep: state.walkthrough.currentStep,
    isFirstVisit: state.firstVisit,
    userFirstName: state.user.data.user.first_name,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { setWalktroughStep, stopWalktrough, updateFirstVisit, fetchUserData },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Walkthrough);
