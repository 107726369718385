import * as TYPES from './types';

const mergePayload = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    ...action.payload,
  },
});

const user = (state = {}, action) => {
  let payload;
  let data;
  switch (action.type) {
    case TYPES.USER_AUTHENTICATED_FAILED:
      return {
        ...state,
        authenticated: false,
      };

    case TYPES.USER_AUTHENTICATED_SUCCESS:
      return {
        ...state,
        authenticated: true,
      };

    case TYPES.USER_LOGOUT_SUCCESS:
      return {
        ...state,
        authenticated: false,
      };
    case TYPES.FETCH_FLEXIBLE_FIELDS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          profile_flexible_field: {
            ...state.data.profile_flexible_field,
            ...action.payload,
          },
        },
      };

    case TYPES.PATCH_USER_DATA:
      return {
        ...state,
        user_data_patched: null,
      };

    case TYPES.PATCH_USER_DATA_SUCCESS:
      return {
        ...mergePayload(state, action),
        user_data_patched: true,
      };

    case TYPES.PATCH_USER_DATA_FAILED:
      return {
        ...state,
        user_data_patched: false,
      };

    case TYPES.FETCH_USER_DATA_SUCCESS:
      payload = action.payload.user.groups.filter(
        (group) => group.group_type === 'datasource'
      );
      data = mergePayload(state, action);
      return {
        ...data,
        learnlinq:
          payload.length > 0 && payload[0].name.indexOf('.studytube.nl') < 0 && payload[0].identifier.indexOf('zzp-source') < 0
            ? { enabled: true, ...payload[0] }
            : { enabled: false },
        studytube:
          payload.length > 0 && payload[0].name.indexOf('.studytube.nl') > -1 && payload[0].identifier.indexOf('zzp-source') < 0
            ? { enabled: true, ...payload[0] }
            : { enabled: false },
      };
  }

  return state;
};

export default {
  user,
};
