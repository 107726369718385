import { getDateAsKebabYmdString } from '../../utils';

export default [
  {
    message: 'CHAT_WELCOME_MESSAGE'
  },
  {
    APIidentifier: 'first_name',
    message: 'CHAT_FIRST_NAME_MESSAGE',
    needUserInput: true,
    placeholder: 'CHAT_FIRST_NAME_PLACEHOLDER',
    required: true
  },
  {
    APIidentifier: 'last_name',
    message: 'CHAT_LAST_NAME_MESSAGE',
    needUserInput: true,
    placeholder: 'CHAT_LAST_NAME_PLACEHOLDER',
    required: true
  },
  {
    message: 'CHAT_LAST_NAME_MESSAGE_FOLLOW_UP_1',
  },
  {
    APIidentifier: 'company',
    message: 'CHAT_COMPANY_MESSAGE',
    needUserInput: true,
    placeholder: 'CHAT_COMPANY_PLACEHOLDER',
    format: 'select',
    required: true
  },
  {
    APIidentifier: 'subtitle',
    maxLength: 100,
    message: 'CHAT_OCUPATION_MESSAGE',
    needUserInput: true,
    placeholder: 'CHAT_OCCUPATION_PLACEHOLDER'
  },
  {
    message: 'CHAT_OCCUPATION_MESSAGE_FOLLOW_UP_1',
  },
  {
    message: 'CHAT_OCCUPATION_MESSAGE_FOLLOW_UP_2',
  },
  {
    message: 'CHAT_OCCUPATION_MESSAGE_FOLLOW_UP_3',
  },
  {
    message: 'CHAT_OCCUPATION_MESSAGE_FOLLOW_UP_4',
  },
  {
    message: 'CHAT_OCCUPATION_MESSAGE_FOLLOW_UP_5',
  },
  {
    APIidentifier: 'has_big_vvn_registration',
    message: 'CHAT_HAS_BIG_VVN_REGISTRATION',
    needUserInput: true,
    isFlexibleField: true,
    format: 'select',
    required: true,
    choices: [{value: 'true', label:'Yes'}, {value: 'false', label: 'No'}],
    defaultValue: 'false'
  },
  {
    APIidentifier: 'big_number',
    message: 'CHAT_BIG_NUMBER_MESSAGE',
    needUserInput: true,
    isFlexibleField: true,
    placeholder: 'CHAT_BIG_NUMBER_PLACEHOLDER',
    required: true,
    pattern: '[xX]|[0-9]{11}|[0-9]{6}',
    title: 'CHAT_BIG_NUMBER_INVALID_MESSAGE' // Een valide BIG nummer heeft in totaal 11 nummers en een V&VN nummer 6
  },
  {
     message: 'CHAT_BIG_NUMBER_X'
  },
  {
    message: 'CHAT_BIG_NUMBER_MESSAGE_FOLLOW_UP_1',
  },
  {
    APIidentifier: 'birthday',
    message: 'CHAT_BIRTHDAY_MESSAGE',
    needUserInput: true,
    isFlexibleField: true,
    format: 'date',
    placeholder: 'CHAT_BIRTHDAY_PLACEHOLDER',
    required: true,
    min: '1900-01-01',
    max: getDateAsKebabYmdString(),
    defaultValue: '1990-09-09'
  },
  {
    APIidentifier: 'gender',
    message: 'CHAT_GENDER_MESSAGE',
    needUserInput: true,
    isFlexibleField: true,
    choices: ['Male', 'Female']
  },
  {
    message: 'CHAT_GENDER_MESSAGE_FOLLOW_UP_1',
  },
  {
    APIidentifier: 'chat_done',
    needUserInput: true,
    choices: ['Verder'],
    isFlexibleField: true,
    isChatFinished: true,
    message: 'CHAT_GOODBYE_MESSAGE'
  }
];
