import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { login } from '../../store/portal/actions';
import FormField from '../../components/form_field';
import { BackButton } from './back-btn';
import Form from '../../components/form/form';
import { translate } from '../../utils';

export class Login extends React.Component {
  componentDidMount() {
    const next = this.props.location.search.split('?next=');
    if (next.length === 2) {
      const nextUrl = decodeURIComponent(next[1]);
      window.localStorage.setItem('nextUrl', nextUrl);
    }
  }

  render() {
    return (
      <div className="content">
        <BackButton to="/portal/register-or-login" />
        <div>
          <h2 className="heading">{translate('LOGIN__EMAIL_TITLE')}</h2>
          <p
            className="instruction"
            dangerouslySetInnerHTML={{ __html: translate('LOGIN__EMAIL_TEXT') }}
          ></p>
          <div className="email-login">
            <Link className="c-button" to="/portal/login/email">
              {translate('LOGIN__EMAIL_BUTTON')}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.portal_errors.login,
    processing: state.portal_processing.login,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login,
    },
    dispatch
  );

export const LoginEmail = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class _LoginEmail extends React.Component {
    state = {};

    componentDidMount() {
      const next = this.props.location.search.split('?next=');
      if (next.length === 2) {
        const nextUrl = decodeURIComponent(next[1]);
        window.localStorage.setItem('nextUrl', nextUrl);
      }
    }

    handleInput = (event) => {
      const { name, value } = event.target;

      this.setState({
        [name]: value,
        dirty: true,
      });
    };

    onSubmit = () => {
      const { email, password } = this.state;
      this.props.login({ email, password });
      this.setState({ dirty: false, submitted: true });
    };

    render() {
      const errors =
        !this.state.dirty && this.state.submitted && this.props.errors;

      return (
        <div className="content">
          <BackButton to="/portal/register-or-login" />
          <article className="form">
            <h2 className="form__heading">{translate('LOGIN__EMAIL_TITLE')}</h2>
            <p
              className="instruction"
              dangerouslySetInnerHTML={{
                __html: translate('LOGIN__EMAIL_TEXT'),
              }}
            ></p>
            <br />
            <Form
              errors={errors}
              onSubmit={this.onSubmit}
              buttonText={'Inloggen'}
              className="form__wrapper"
              processing={this.props.processing}
            >
              <FormField
                errors={errors}
                className="form__field"
                type="e-mail"
                placeholder="E-mailadres"
                required={true}
                name="email"
                onChange={this.handleInput}
              />
              <FormField
                errors={errors}
                className="form__field"
                type="password"
                required={true}
                placeholder="Wachtwoord"
                name="password"
                onChange={this.handleInput}
              />
            </Form>

            <div className="form__actions">
              <Link
                className="form__action"
                to="/portal/forgot-password"
              >{`Wachtwoord vergeten?`}</Link>
              <Link
                className="form__action"
                to="/portal/register"
              >{`Geen account?`}</Link>
            </div>
          </article>
        </div>
      );
    }
  }
);
