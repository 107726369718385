import { put, all, takeLatest } from 'redux-saga/effects';

import * as TYPES from './types';
import { getApi } from '../../api';


function* fetchRapportageStatus() {
  const api = getApi();
  try {
    const response = yield api.rapportageStatus();
    yield put({ type: TYPES.RAPPORTAGE_STATUS, payload: response });
  } catch({ message }) {
    console.log(message);
  }
}


function* rapportageListener() {
  yield takeLatest(TYPES.FETCH_RAPPORTAGE_STATUS, fetchRapportageStatus);
}

export default function* rapportageSaga() {
  yield all([
    rapportageListener()
  ]);
}
