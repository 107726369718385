import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { confirmPassword } from '../../store/portal/actions';
import FormField from '../../components/form_field';
import { BackButton } from './back-btn';
import { translate } from '../../utils';
import Form from '../../components/form/form';

class ForgotPasswordConfirm extends React.Component {
  state = {
    ...this.props.match.params
  };

  handleInput = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  }

  submit = () => {
    const { uid, token, new_password, new_password_repeat } = this.state;
    this.props.confirmPassword({uid, token, new_password, new_password_repeat});
  }

  render() {
    const { errors, processing } = this.props;
    return (
      <div className="content">
        <BackButton to="/portal/login" />
        <article className="form">
          <h2 className="form__label">{translate('PASSWORD_FORGOT_CONFIRM__TITLE')}</h2>
          <p className="form__content">
            {translate('PASSWORD_FORGOT_CONFIRM__TEXT')}
          </p>
          <Form
            errors={errors}
            onSubmit={this.submit}
            buttonText={'Wachtwoord opslaan'}
            buttonDisabled={processing}
            className="form__wrapper"
          >
            <FormField
              errors={errors}
              className="form__field"
              type="password"
              required={true}
              placeholder="Nieuw wachtwoord"
              name="new_password"
              onChange={this.handleInput}
            />
            <FormField
              errors={errors}
              className="form__field"
              type="password"
              required={true}
              placeholder="Herhaal nieuw wachtwoord"
              name="new_password_repeat"
              onChange={this.handleInput}
            />
          </Form>
        </article>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    errors: state.portal_errors.confirmPassword,
    processing: state.portal_processing.confirmPassword
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      confirmPassword
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordConfirm);
