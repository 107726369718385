export const CHECK_AUTHENTICATED = 'CHECK_AUTHENTICATED';
export const USER_AUTHENTICATED_SUCCESS = 'USER_AUTHENTICATED_SUCCESS';
export const USER_AUTHENTICATED_FAILED = 'USER_AUTHENTICATED_FAILED';

export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED';

export const FETCH_USER_DATA = 'FETCH_USER_DATA';
export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
export const FETCH_USER_DATA_FAILED = 'FETCH_USER_DATA_FAILED';

export const PATCH_USER_DATA = 'PATCH_USER_DATA';
export const PATCH_USER_DATA_SUCCESS = 'PATCH_USER_DATA_SUCCESS';
export const PATCH_USER_DATA_FAILED = 'PATCH_USER_DATA_FAILED';

export const FETCH_FLEXIBLE_FIELDS_SUCCESS = 'FETCH_FLEXIBLE_FIELDS_SUCCESS';
export const FETCH_FLEXIBLE_FIELDS_FAILED = 'FETCH_FLEXIBLE_FIELDS_FAILED';

export const UPDATE_INTRO = 'UPDATE_INTRO';
