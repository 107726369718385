import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Nav from '../../components/nav';
import { Login, LoginEmail } from './login';
import ForgotPassword from './forgot-pass';
import ForgotPasswordConfirm from './forgot-pass-confirm';
import Register from './register';
import Contact from './contact';
import About from './about';
import RegisterOrLogin from './register-or-login';
import Entry from './entry';
import './styles.css';
import Logo from '../../assets/img/logo.svg';
import EmailVerify from './email-verify';
import Failed from "./failed";

class Portal extends React.Component {
  render() {
    const { ready } = this.props;
    return (
      <section className="s-portal">
        <div className="bg-img" />
        <div className="section">
          <div
            className="logo"
            onClick={() => this.props.history.push('/portal')}
          >
            <img src={Logo} />
          </div>
        </div>
        <div className="section">
          <Nav className="nav" />
          <div className="container">
            {ready && (
              <TransitionGroup component={null}>
                <CSSTransition
                  classNames="anim-portal"
                  timeout={400}
                  key={this.props.location.pathname}
                >
                  <Switch location={this.props.location}>
                    <Route
                      exact
                      path="/portal/failed/:code"
                      component={Failed}
                    />
                    <Route
                      exact
                      path="/portal/verify"
                      component={EmailVerify}
                    />
                    <Route exact path="/portal/login" component={Login} />
                    <Route
                      exact
                      path="/portal/login/email"
                      component={LoginEmail}
                    />
                    <Route
                      path="/portal/forgot-password/:uid/:token"
                      component={ForgotPasswordConfirm}
                    />
                    <Route
                      path="/portal/forgot-password"
                      component={ForgotPassword}
                    />
                    <Route path="/portal/code/:token" component={Register} />
                    <Route path="/portal/register" component={Register} />
                    <Route
                      exact={true}
                      path="/portal/contact"
                      component={Contact}
                    />
                    <Route
                      exact={true}
                      path="/portal/about"
                      component={About}
                    />
                    <Route
                      exact={true}
                      path="/portal/register-or-login"
                      component={RegisterOrLogin}
                    />
                    <Route exact={true} path="/portal" component={Entry} />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            )}
          </div>
        </div>
      </section>
    );
  }
}

Portal.propTypes = {
  ready: PropTypes.bool,
  location: PropTypes.object
};

const mapStateToProps = state => {
  return {
    ready: state.app.ready
  };
};

export default connect(
  mapStateToProps,
  {}
)(Portal);
