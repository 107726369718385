import * as TYPES from './types';

export const postModuleStart = uuid => ({
  type: TYPES.MODULE_START,
  payload: { uuid }
});

export const fetchModules = () => ({
  type: TYPES.FETCH_MODULES
});

export const submitPEOnline = ({uuid, big_number, relation_number}) => ({
  type: TYPES.SUBMIT_PEONLINE,
  payload: { uuid, big_number, relation_number }
})
