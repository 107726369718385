import * as TYPES from './types';

const defaultDiariesState = {
  results: []
};

const diaries = (state = defaultDiariesState, { type, payload }) => {
  switch (type) {
    case TYPES.FETCH_DIARIES_SUCCESS:
      return {
        fetched: true,
        results: payload
      };
    default:
      return state;
  }
};

const diary = (state = defaultDiariesState, action) => {
  switch (action.type) {
    case TYPES.FETCH_DIARY:
      return state.filter(diary => diary.uid === action.uid);
    default:
      return state;
  }
};

export default {
  diaries,
  diary
};
