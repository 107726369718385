import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

class Dropdown extends React.Component {
  render() {
    const { items, onChange, defaultValue } = this.props;
    return (
      <div className="c-dropdown">
        <select className="select-css" onChange={onChange} defaultValue={defaultValue}>
          {items &&
            items.map((item, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              );
            })}
        </select>
      </div>
    );
  }
}

Dropdown.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string
};

export default Dropdown;
