import { all, takeLatest } from 'redux-saga/effects';

import * as TYPES from './types';
import { updateFlexibleField } from '../user/sagas';

function* walkthroughFinishedListener() {
  yield takeLatest(TYPES.STOP_WALKTHROUGH, updateFlexibleField);
}

export default function* dashboardSaga() {
  yield all([walkthroughFinishedListener()]);
}
