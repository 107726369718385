import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';

import { translate } from '../../utils';
import { get_studytube_login } from '../../utils/studytube';
import { Logo } from '../../components/logo';
import imageBed from '../../assets/img/entree-bed.svg';
import imageBookcase from '../../assets/img/entree-bookcase.svg';
import imageBoard from '../../assets/img/entree-bulletinboard.svg';
import imageTable from '../../assets/img/entree-coffeetable.svg';
import imageDesk from '../../assets/img/entree-desk.svg';
import imageTv from '../../assets/img/entree-tv.svg';
import './styles.css';
import { LXP_URL } from "../../variables";

class Entree extends React.Component {
  render() {
    const { history, learnlinq, studytube } = this.props;
    const area = this.props.match.params.area;

    const image = ((area) => {
      switch (area) {
        case 'library':
          return imageBookcase;
        case 'board':
          return imageBoard;
        case 'diaries':
          return imageTable;
        case 'timeline':
          return imageDesk;
        case 'profile':
          return imageTv;
        case 'ask-me':
          return imageBed;
      }
    })(area);

    const ll = (learnlinq.enabled || studytube.enabled) && area === 'library' ? 'LL_' : '';
    const title = `${ll}${area}_entree_heading`.toUpperCase();
    const text = `${ll}${area}_entree_text`.toUpperCase();

    return (
      <section className={classNames({ 's-entree': true })}>
        <div className="bg-image">
          <img src={image} />
        </div>

        <div className={classNames({ logo: true, [area]: area })}>
          <Logo className="logo__svg" />
        </div>

        <div className="container">
          <div className="content">
            <h2 className="title">{translate(title)}</h2>
            <p className="text">{translate(text)}</p>
            <div className="buttons">
              <button
                className="button"
                onClick={
                  learnlinq.enabled && area === 'library'
                    ? () =>
                      (window.location.href = `https://${learnlinq.name}.learnlinq.com/#!/catalog`)
                    : studytube.enabled && area === 'library'
                      ? () =>
                        (window.open(get_studytube_login(studytube.name, "/discover"), "_blank"))
                      : area === "board" ? () => {
                        window.location = LXP_URL + '/#/' + "?jwt=" + window.localStorage.getItem("token")
                      } : () => history.replace(`/${area}`)
                }
              >
                Ja
              </button>
              <button className="button no" onClick={() => history.goBack()}>
                Nee
              </button>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    learnlinq: state.user.learnlinq,
    studytube: state.user.studytube,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Entree);
