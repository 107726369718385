import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../components/button';
import './styles.css';
import Lti from '../../utils/lti';
import clientThemeIcon from '../../assets/img/Persoonsgerichte-zorg_v3.jpg';
import teamThemeIcon from '../../assets/img/Teamontwikkeling_v2.png';
import talentThemeIcon from '../../assets/img/Persoonlijke-Ontwikkeling.png';
import { IconModuleComplete, IconModuleStarted } from '../../components/icons';
import { translate } from '../../utils';

class AccordionItem extends React.Component {
  state = {
    isOpen: false,
    openModule: false
  };

  componentDidMount() {
    if (this.props.open) {
      this.setState({ isOpen: true });
    }
  }

  onToggleAccordion = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }), () => {
      if (this.state.isOpen) {
        this.props.history.replace('#' + this.props.uuid);
      } else {
        this.props.history.replace(this.props.match.url);
      }
    });
  };

  openLearningModule = () => {
    this.props.startHandler
      ? this.props.startHandler()
      : this.setState({ openModule: true });
  };

  onModuleStarted = () => {
    this.setState({ openModule: false });
  };

  render() {
    const { isOpen, openModule } = this.state;
    const {
      title,
      innerRef,
      description,
      uuid,
      completed,
      started,
      theme,
      extraComponent
    } = this.props;
    const icon = (theme => {
      switch (theme) {
        case 'Ik en mijn Cliënt':
          return (
            <img
              src={clientThemeIcon}
              width={'35px'}
              height={'35px'}
              title={translate('Persoonsgerichte zorg')}
            />
          );
        case 'Ik en mijn Team':
          return (
            <img
              src={teamThemeIcon}
              width={'35px'}
              height={'35px'}
              title={translate('Teamontwikkeling')}
            />
          );
        case 'Ik en mijn Talent':
          return (
            <img
              src={talentThemeIcon}
              width={'35px'}
              height={'35px'}
              title={translate('Persoonlijke ontwikkeling')}
            />
          );
        default:
          return '';
      }
    })(theme);
    return (
      <div ref={innerRef} className="c-accordion-item">
        <div className="item" onClick={this.onToggleAccordion}>
          <div className="icon">{icon}</div>
          <div className="title">{title}</div>
          <div className="status">
            {completed ? (
              <IconModuleComplete size={'30'} title={translate('MODULE_FINISHED_ICON')} />
            ) : started ? (
              <IconModuleStarted size={'30'} title={translate('MODULE_INPROGRESS_ICON')} />
            ) : null}
          </div>
        </div>
        {isOpen && (
          <div className="accordion">
            <p className="description">{description}</p>
            <Button
              disabled={openModule}
              onClick={this.openLearningModule}
              text={translate('START_MODULE')}
            />
            {extraComponent}
          </div>
        )}
        {openModule && (
          <Lti onStarted={this.onModuleStarted} uuid={uuid} target="_blank" />
        )}
      </div>
    );
  }
}

AccordionItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  theme: PropTypes.string,
  uuid: PropTypes.string,
  completed: PropTypes.bool,
  started: PropTypes.bool,
  extraComponent: PropTypes.node
};

export default AccordionItem;
