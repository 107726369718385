import { put, takeLatest, all } from 'redux-saga/effects';
import { replace, push } from 'connected-react-router';

import { getApi } from '../../api';
import * as TYPES from './types';
import {checkAuthenticated} from "../user/sagas";

function* createAccount({ payload }) {
  const api = getApi();
  try {
    yield api.postRegister(payload);
    yield put(push('/portal/register/success'));
    yield put({ type: TYPES.CREATE_ACCOUNT_POST_SUCCESS });
  } catch (error) {
    yield put({ type: TYPES.CREATE_ACCOUNT_POST_FAILED, error });
  }
}

function* login({ payload }) {
  const api = getApi();
  try {
    const response = yield api.postLogin(payload);
    yield put({ type: TYPES.LOGIN_POST_SUCCESS, payload: payload });
    yield checkAuthenticated();
    yield api.requestCookie();
    yield put(replace('/'));
  } catch (error) {
    yield put({ type: TYPES.LOGIN_POST_FAILED, error });
  }
}

function* deleteAccount() {
  const api = getApi();
  try {
    yield api.deleteAccount();
    yield put(replace('/portal'));
    yield put({ type: TYPES.DELETE_ACCOUNT_SUCCESS });
  } catch (error) {
    yield put({ type: TYPES.DELETE_ACCOUNT_FAILED, error });
  }
}

function* resetPassword({ email }) {
  const api = getApi();
  try {
    yield api.resetPassword(email);
    yield put(push('/portal/forgot-password/requested'));
    yield put({ type: TYPES.RESET_PASSWORD_SUCCESS, payload: email });
  } catch (error) {
    yield put({ type: TYPES.RESET_PASSWORD_FAILED, error });
  }
}

function* confirmPassword(payload) {
  const api = getApi();
  try {
    yield api.confirmPassword(payload);
    yield put(push('/portal/forgot-password/success'));
    yield put({ type: TYPES.CONFIRM_PASSWORD_SUCCESS, payload: payload });
  } catch (error) {
    yield put({ type: TYPES.CONFIRM_PASSWORD_FAILED, error });
  }
}

function* verifyEmail(payload) {
  const api = getApi();
  try {
    yield api.verifyEmail(payload);
    yield put(replace('/portal/login/email'));
    yield put({ type: TYPES.VERIFY_EMAIL_SUCCESS, payload: payload });
  } catch (error) {
    yield put({ type: TYPES.VERIFY_EMAIL_FAILED, error });
  }
}

export function* createAccountListener() {
  yield takeLatest(TYPES.CREATE_ACCOUNT_POST, createAccount);
}

export function* loginListener() {
  yield takeLatest(TYPES.LOGIN_POST, login);
}

export function* resetPasswordListener() {
  yield takeLatest(TYPES.RESET_PASSWORD, resetPassword);
}

export function* deleteAccountListener() {
  yield takeLatest(TYPES.DELETE_ACCOUNT, deleteAccount);
}

export function* confirmPasswordListener() {
  yield takeLatest(TYPES.CONFIRM_PASSWORD, confirmPassword);
}

export function* verifyEmailListener() {
  yield takeLatest(TYPES.VERIFY_EMAIL, verifyEmail);
}

export default function* portalSaga() {
  yield all([
    createAccountListener(),
    loginListener(),
    resetPasswordListener(),
    deleteAccountListener(),
    confirmPasswordListener(),
    verifyEmailListener()
  ]);
}
