import * as TYPES from './types';

const portal_errors = (state = {}, action) => {
  switch (action.type) {
    case TYPES.CREATE_ACCOUNT_POST_FAILED:
      return {
        ...state,
        register: action.error.content
      };

    case TYPES.LOGIN_POST_FAILED:
      return {
        ...state,
        login: action.error.content
      };

    case TYPES.DELETE_ACCOUNT_FAILED:
      return {
        ...state,
        deleteAccount: action.error.content
      };

    case TYPES.RESET_PASSWORD_FAILED:
      return {
        ...state,
        resetPassword: action.error.content
      };

    case TYPES.CONFIRM_PASSWORD_FAILED:
      return {
        ...state,
        confirmPassword: action.error.content
      };
    case TYPES.VERIFY_EMAIL_FAILED:
      return {
        ...state,
        verifyEmail: action.error.content
      };
  }

  return state;
};

const portal_processing = (state = {}, action) => {
  switch (action.type) {
    case TYPES.LOGIN_POST:
      return { ...state, login: true };
    case TYPES.LOGIN_POST_SUCCESS:
    case TYPES.LOGIN_POST_FAILED:
      return { ...state, login: false };

    case TYPES.CREATE_ACCOUNT_POST:
      return { ...state, register: true };
    case TYPES.CREATE_ACCOUNT_POST_SUCCESS:
    case TYPES.CREATE_ACCOUNT_POST_FAILED:
      return { ...state, register: false };

    case TYPES.VERIFY_EMAIL:
      return { ...state, verifyEmail: true };
    case TYPES.VERIFY_EMAIL_SUCCESS:
    case TYPES.VERIFY_EMAIL_FAILED:
      return { ...state, verifyEmail: false };

    case TYPES.RESET_PASSWORD:
      return { ...state, resetPassword: true };
    case TYPES.RESET_PASSWORD_SUCCESS:
    case TYPES.RESET_PASSWORD_FAILED:
      return { ...state, resetPassword: false };
  }

  return state;
};

export default {
  portal_processing,
  portal_errors
};
