import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createAccount } from '../../store/portal/actions';
import FormField from '../../components/form_field';
import { BackButton } from './back-btn';
import Form from '../../components/form/form';
import { Route } from 'react-router-dom';
import { translate } from '../../utils';

class Register extends React.Component {
  state = {
    email: undefined,
    password1: undefined,
    password2: undefined
  };

  handleInput(event) {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
      dirty: true,
      submitted: true
    });
  }

  onSubmit = () => {
    const { email, password1, password2 } = this.state;
    const { match: {params: {token}}} = this.props;

    this.props.createAccount({
      password1,
      password2,
      ...(token ? { activation_token: token } : { email })
    });
    this.setState({ dirty: false });
  };

  render() {
    const {
      match: { path, params: {token} }
    } = this.props;
    const errors =
      !this.state.dirty && this.state.submitted && this.props.errors;

    return (
      <div className="content">
        <BackButton to="/portal/register-or-login" />
        <article className="form">
          <Route
            exact
            path={path}
            render={() => (
              <>
                <h2 className="form__heading">
                  {translate('REGISTER__TITLE')}
                </h2>
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: translate('REGISTER__TEXT')
                  }}
                ></div>
                <br />
                <Form
                  buttonDisabled={this.props.processing}
                  errors={errors}
                  onSubmit={this.onSubmit}
                  processing={this.props.processing}
                  buttonText={'Maak account'}
                  className="form__wrapper"
                >
                  {!token ? (
                    <FormField
                      errors={errors}
                      className="form__field"
                      type="email"
                      required={true}
                      placeholder="E-mailadres"
                      name="email"
                      onChange={this.handleInput.bind(this)}
                    />
                  ) : errors && errors.activation_token && (
                    <p>{translate('REGISTER__INVITE_ALREADY_USED')}</p>
                  )}
                  <FormField
                    errors={errors}
                    className="form__field"
                    type="password"
                    required={true}
                    placeholder="Wachtwoord"
                    minLength={8}
                    name="password1"
                    onChange={this.handleInput.bind(this)}
                  />
                  <FormField
                    errors={errors}
                    className="form__field"
                    required={true}
                    type="password"
                    placeholder="Bevestig wachtwoord"
                    minLength={8}
                    name="password2"
                    onChange={this.handleInput.bind(this)}
                  />
                </Form>
              </>
            )}
          />
          <Route
            exact
            path={`${path}/success`}
            render={() => (
              <>
                <h2 className="form__heading">
                  {translate('REGISTER_SUCCESS__TITLE')}
                </h2>
                <p
                  className="form__content"
                  dangerouslySetInnerHTML={{
                    __html: translate('REGISTER_SUCCESS__TEXT')
                  }}
                />
              </>
            )}
          />
        </article>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    errors: state.portal_errors.register,
    processing: state.portal_processing.register
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createAccount
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
