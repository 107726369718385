import * as TYPES from './types';

export const fetchDiaries = () => ({
  type: TYPES.FETCH_DIARIES
});

export const fetchDiary = (uid) => ({
  type: TYPES.FETCH_DIARY,
  uid
});
