import React from 'react';
import { connect } from 'react-redux';
import chatScript from './chat-script';

import { translate } from '../../utils';
import Chat from '../../components/chat';
import Header from '../../components/header';
import './styles.css';

function getCompanyChoices(state) {
  return ['CHAT_COMPANY_DEFAULT', ...state.app.organisations].map(
    (choice, i) => ({
      value: i === 0 ? '' : choice,
      label: choice,
    })
  );
}

const LearnLinqChatScript = [
  { message: translate('LL_CHAT_MESSAGE_1') },
  { message: translate('LL_CHAT_MESSAGE_2') },
  { message: translate('LL_CHAT_MESSAGE_3') },
  { message: translate('LL_CHAT_MESSAGE_4') },
  { message: translate('LL_CHAT_MESSAGE_5') },
  {
    message: translate('LL_CHAT_MESSAGE_6'),
    APIidentifier: 'chat_done',
    choices: ['Verder'],
    isChatFinished: true,
    isFlexibleField: true,
    needUserInput: true,
  },
];

class ChatRoom extends React.Component {
  render() {
    const { chatScript, learnlinq, studytube, ...props } = this.props;
    const script = learnlinq.enabled || studytube.enabled ? LearnLinqChatScript : chatScript;

    return (
      <section className="s-chat-room">
        <div className="bg-img"></div>
        <Header {...props} isMenuHidden />
        <div className="responsive-container">
          <Chat script={script} history={this.props.history} />
        </div>
      </section>
    );
  }
}

export default connect((state) => ({
  chatScript: chatScript.reduce((acc, msg, i) => {
    if (msg.APIidentifier === 'company') {
      acc[i].choices = getCompanyChoices(state);
    }
    return acc;
  }, chatScript),
  learnlinq: state.user.learnlinq,
  studytube: state.user.studytube,
}))(ChatRoom);
