import React from 'react';
import linkifyHtml from 'linkifyjs/html';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactPlayer from 'react-player';
import 'moment/locale/nl';

import { like, submitComment, unlike, deleteActivity, pinActivity, unpinActivity } from '../../store/timeline/actions';
import { translate } from '../../utils';
import Comment from './comment';
import { IconSend, IconLike, IconAttachment } from '../../components/icons';
import Form from '../../components/form/form';

class Activity extends React.Component {
  state = {
    showMoreComments: false,
    isOwnActivity: false
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const activityUID = this.props.data.user.id;
    const uid = this.props.uid;
    this.setState({ isOwnActivity: activityUID === uid });
  }

  onDeleteActivity() {
    if(window.confirm(translate('TIMELINE_DELETE_CONFIRMATION_TEXT'))){
      this.props.deleteActivity(this.props.data.id)
    }
  }

  onUpdateActivity() {
    this.props.history.push(`/timeline/${this.props.data.content.id}`)
  }


  onPinActivity() {
    this.props.pinActivity(this.props.data.id);
  }

  onUnpinActivity() {
    this.props.unpinActivity(this.props.data.id);
  }

  onChangeComment(e) {
    this.setState({ comment: e.target.value });
  }

  comment() {
    const comment = this.state.comment;
    comment && this.props.submitComment({ id: this.props.data.id, comment });
    this.setState({ comment: '' });
  }

  getFormatedDate(date) {
    return moment(date)
      .locale('nl')
      .calendar();
  }

  showMoreComments = () => {
    this.setState(prevState => ({
      showMoreComments: !prevState.showMoreComments
    }));
  };

  renderMedia = attachment => {
    const isVideo = ['mp4', 'avi', 'mov', 'mpg', 'wmv', '3gp'].includes(
      attachment.document
        .split('.')
        .slice(-1)[0]
        .toLowerCase()
    );
    const isImage = ['jpg','jpeg', 'gif', 'png', 'svg'].includes(
      attachment.document
        .split('.')
        .slice(-1)[0]
        .toLowerCase()
    );
    return isVideo ? (
      <ReactPlayer
        controls
        className="message__attachment"
        url={attachment.document}
        width="100%"
      />
    ) : isImage ? (
      <a
        key={attachment.id}
        href={attachment.document}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="message__attachment" src={attachment.document} />
      </a>
    ) : (
      <a
      className="file-preview"
      key={attachment.id}
      href={attachment.document}
      target="_blank"
      rel="noopener noreferrer"
      >
        <IconAttachment color={'#555'}/>
        <p>{attachment.title}</p>
      </a>
        );
  };

  render() {
    const { data, liked, processing, isAdmin } = this.props;
    const { showMoreComments, isOwnActivity } = this.state;

    const comments = (data.comments || [])
      .filter(({ user }) => user)
      .sort((a, b) => {
        return a.submit_date < b.submit_date
          ? 1
          : a.submit_date > b.submit_date
          ? -1
          : 0;
      });

    const shownComments = comments.filter((comment, index) => index <= 1);
    const hiddenComments = comments.filter((comment, index) => index > 1);
    const showPinLinks = isOwnActivity && isAdmin;

    return (
      <section className="activity">
        <article className="message">
          <div className="message__info">
            <img className="message__media" src={data.user.image_url} />
            <p className="message__date">
              {this.getFormatedDate(data.created_at)}
            </p>
            <div className="message__info__right-container">
              <p className="message__likes">
                <IconLike
                  className="message__likes__icon"
                  size={'12'}
                  color={'#fff'}
                />
                {data.likes.length} {translate('TIMELINE_LIKES')}
              </p>
              {showPinLinks && (
                data.sticky
                ? <p className="message__pin" onClick={this.onUnpinActivity.bind(this)}>{translate('TIMELINE_UNPIN')}</p>
                : <p className="message__pin" onClick={this.onPinActivity.bind(this)}>{translate('TIMELINE_PIN')}</p>
              )}
              {(isOwnActivity || isAdmin) && (
                <>
                  <p className="message__update" onClick={this.onUpdateActivity.bind(this)}>{translate('TIMELINE_UPDATE')}</p>
                  <p className="message__delete" onClick={this.onDeleteActivity.bind(this)}>{translate('TIMELINE_DELETE')}</p>
                </>
              )}
            </div>
          </div>

          <div className="message__main">
            <div className="message__content">
              <p className="message__name">{data.user.name}</p>
              {data.content.attachments.map(this.renderMedia)}
              <div
                className="message__text"
                dangerouslySetInnerHTML={{
                  __html: linkifyHtml(data.content.message)
                }}
              />
            </div>
          </div>

          <div className="message__btns">
            {liked ? (
              <button
                onClick={this.props.unlike}
                className="message__btn message__btn-unlike"
              >
                {translate('TIMELINE_UNLIKE')}
                <IconLike
                  className="message__btn__icon"
                  size={'12'}
                  color={'#78b4b3'}
                />
              </button>
            ) : (
              <button
                onClick={this.props.like}
                className="message__btn message__btn-like"
              >
                {translate('TIMELINE_LIKE')}
                <IconLike
                  className="message__btn__icon"
                  size={'12'}
                  color={'#fff'}
                />
              </button>
            )}
          </div>
        </article>

        <article className="interaction">
          <p className="interaction__info">
            {comments.length} {translate('TIMELINE_REACTIONS')}
          </p>
          {shownComments.length > 0 &&
            shownComments.map(({ user, comment, id, likes }) => (
              <Comment
                key={id}
                userImage={user.image_url}
                userName={user.name}
                id={id}
                comment={comment}
                likes={likes}
              />
            ))}
          {hiddenComments.length > 0 && (
            <div
              className="interaction__show-more"
              onClick={this.showMoreComments}
            >
              {!showMoreComments
                ? translate('TIMELINE_SHOW_MORE_REACTIONS_TEXT')
                : translate('TIMELINE_SHOW_LESS_REACTIONS_TEXT')}
            </div>
          )}
          {hiddenComments.length > 0 &&
            showMoreComments &&
            hiddenComments.map(({ user, comment, id, likes }) => (
              <Comment
                key={id}
                userImage={user.image_url}
                userName={user.name}
                id={id}
                comment={comment}
                likes={likes}
              />
            ))}
          <Form
            buttonText={<IconSend color={'#fff'} />}
            onSubmit={this.comment.bind(this)}
            className="input-group"
          >
            <input
              className="input-group__input"
              value={this.state.comment}
              type="text"
              required
              placeholder={translate('TIMELINE_COMMENT_INPUT_PLACEHOLTER')}
              onChange={this.onChangeComment.bind(this)}
            />
          </Form>
        </article>
      </section>
    );
  }
}

Activity.propTypes = {
  data: PropTypes.object,
  processing: PropTypes.bool,
  like: PropTypes.func,
  unlike: PropTypes.func,
  submitComment: PropTypes.func,
  liked: PropTypes.bool,
  deleteActivity: PropTypes.func,
  pinActivity: PropTypes.func,
  unpinActivity: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  return {
    isAdmin: state.user.data.is_admin,
    uid: state.user.data.user.pk,
    processing: state.timeline_processing.commenting,
    liked: !!ownProps.data.likes.find(
      ({ user: { id } }) => id == state.user.data.user.pk
    )
  };
};

const mapDispatchToProps = (dispatch, ownProps) =>
  bindActionCreators(
    {
      submitComment,
      deleteActivity,
      pinActivity,
      unpinActivity,
      like: like.bind(null, ownProps.data.id),
      unlike: unlike.bind(null, ownProps.data.id)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Activity);
