import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {NavLink} from 'react-router-dom';
import { translate } from '../../utils';

import { IconProfile, IconInfo, IconQuery } from '../icons';
import './styles.css';


class Nav extends React.Component {
  render() {
    const { className } = this.props;

    return (
      <nav className={classNames({ 'c-nav': true, [className]: className })}>
        <NavLink to={`/portal/contact`} className="c-nav__link" activeClassName="state-active">
          <span className="c-nav__icon"><IconQuery color="#FFF" size="42" /></span>
          <span className="c-nav__text">{translate('PORTAL_NAVIGATION_CONTACT_TEXT')}</span>
        </NavLink>
        <NavLink to={`/portal/about`} className="c-nav__link" activeClassName="state-active">
          <span className="c-nav__icon"><IconInfo color="#FFF" size="42" /></span>
          <span className="c-nav__text">{translate('PORTAL_NAVIGATION_INFO_TEXT')}</span>
        </NavLink>
      </nav>
    );
  }
}

Nav.propTypes = {
  className: PropTypes.string,
};

export default Nav;
