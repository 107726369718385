import * as TYPES from './types';

const library = (
  state = {
    loading: true,
    modules: [],
    categories: []
  },
  action
) => {
  let modules;
  switch (action.type) {
    case TYPES.FETCH_MODULES_SUCCESS:
      modules = action.payload
        .filter(({ translatable_content: { type } }) => type == 'library')
        .sort((a, b) => (a.title < b.title ? -1 : a.title > b.title ? 1 : 0));
      return {
        loading: false,
        modules,
        categories: Object.values(
          modules.reduce(
            (mem, { translatable_content: { category } }) => ({
              ...mem,
              [category.trim()]: {
                label: category.trim(),
                value: category.trim()
              }
            }),
            {}
          )
        ).sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0))
      };
    case TYPES.POST_MODULE_START_SUCCESS:
      return {
        ...state,
        modules: state.modules.map(module =>
          module.uuid == action.payload.uuid
            ? {
                ...module,
                started_at: true
              }
            : module
        )
      };

    default:
      return state;
  }
};

export default {
  library
};
