import { delay, put, all, takeLatest } from 'redux-saga/effects';

import { getApi } from '../../api';
import * as TYPES from './types';
import { translate } from '../../utils';

function* fetchModules() {
  const api = getApi();
  try {
    yield delay(50);
    const response = yield api.fetchModules();
    yield put({ type: TYPES.FETCH_MODULES_SUCCESS, payload: response });
  } catch ({ message }) {
    yield put({ type: TYPES.FETCH_MODULES_FAILED, payload: { message } });
  }
}

function* moduleStarted({ payload }) {
  const api = getApi();
  try {
    yield api.postModuleStart(payload.uuid);
    yield put({ type: TYPES.POST_MODULE_START_SUCCESS, payload });
  } catch {
    yield put({ type: TYPES.POST_MODULE_START_FAILED, payload });
  }
}

function* submitPEOnline({ payload }) {
  const api = getApi();
  try {
    const response = yield api.submitPEOnline(payload);
    yield put({ type: TYPES.SUBMIT_PEONLINE_SUCCESS, response });
    yield fetchModules();
    alert(translate('SUCCESS_IN_REGISTER_SUBMISSION'));
  } catch (e) {
    yield put({ type: TYPES.SUBMIT_PEONLINE_FAILED, e });
    const errorMessage = `${translate(
      'ERROR_IN_REGISTER_SUBMISSION'
    )} (${e.content.map(err => err[1]).join(' ; ')})`;
    alert(errorMessage);
  }
}

function* userAuthListener() {
  yield takeLatest(TYPES.USER_AUTHENTICATED_SUCCESS, fetchModules);
}

function* moduleStartListener() {
  yield takeLatest(TYPES.MODULE_START, moduleStarted);
}

function* fetchModulesListener() {
  // this seems to be dead code?? seems like we're not using this listener.
  yield takeLatest(TYPES.FETCH_MODULES, fetchModules);
}
function* submitPEOnlineListener() {
  yield takeLatest(TYPES.SUBMIT_PEONLINE, submitPEOnline);
}

export default function* librarySaga() {
  yield all([
    userAuthListener(),
    moduleStartListener(),
    fetchModulesListener(),
    submitPEOnlineListener()
  ]);
}
