export default [{
  identifier: 'INTRO',
  popupPosition: 'bottom'
},
{
  identifier: 'LIBRARY',
  popupPosition: 'bottom'
},
{
  identifier: 'BULLETIN_BOARD',
  popupPosition: 'bottom'
},
{
  identifier: 'TIMELINE',
  popupPosition: 'bottom'
},
{
  identifier: 'PROFILE',
  popupPosition: 'bottom'
},
{
  identifier: 'ASK_ME',
  popupPosition: 'bottom'
},
{
  identifier: 'DIARIES',
  popupPosition: 'bottom'
}
]
