import * as TYPES from './types';


export const createAccount = (payload) => ({
  type: TYPES.CREATE_ACCOUNT_POST,
  payload,
});

export const login = (payload) => ({
  type: TYPES.LOGIN_POST,
  payload,
});

export const verifyEmail = (payload) => ({
  type: TYPES.VERIFY_EMAIL,
  payload,
});

export const deleteAccount = () => ({
  type: TYPES.DELETE_ACCOUNT,
});

export const resetPassword = (email) => ({
  type: TYPES.RESET_PASSWORD,
  email,
});

export const confirmPassword = (payload) => ({
  type: TYPES.CONFIRM_PASSWORD,
  uid: payload.uid,
  token: payload.token,
  new_password: payload.new_password,
  new_password_repeat: payload.new_password_repeat,
});
