import { store } from '../store';
import { getApi } from '../api';
import { matchNonSlugChars } from './regex';

function translate(identifier, obj, named) {
  const translations = store.getState().translations;
  let fmt;
  if (!translations.data) {
    fmt = identifier;
  } else {
    fmt = translations.data[identifier];
  }
  if (!fmt && translations.loaded) {
    getApi().apiPromise.then(api => {
      api.action(['localization', 'translatable_item', 'create'], {
        identifier: identifier
      });
    });
    translations.data[identifier] = identifier
    return identifier;
  }
  if (named) {
    return fmt.replace(/%\(\w+\)s/g, function(match) {
      return String(obj[match.slice(2, -2)]);
    });
  } else {
    return fmt.replace(/%s/g, function(match) {
      return String(obj.shift());
    });
  }
}

function getDateAsKebabYmdString() {
  const today = new Date();
  const month = lpad(''+(today.getMonth()+1), 2);
  const day = lpad(''+today.getDate(), 2);
  return `${today.getFullYear()}-${month}-${day}`
}

function lpad(str, minLength, padChar='0') {
  while (str.length<minLength) str=''+padChar+str;
  return str;
}

function slugify(str) {
  return str
    .replace(matchNonSlugChars, '-')
    .replace(/-{1,}/g, '-')
    .toLowerCase();
}

function memoize(fn, getCacheKey = firstArg => firstArg) {
  const memo = new Map();
  return (...args) => {
    const key = getCacheKey(...args);
    if (!memo.has(key)) {
      memo.set(key, fn(...args));
    }
    return memo.get(key);
  };
};

export { getDateAsKebabYmdString, memoize, slugify, translate };
