import * as TYPES from './types';

export const fetchActivities = () => ({
  type: TYPES.FETCH_ACTIVITIES
});

export const fetchActivity = id => ({
  type: TYPES.FETCH_ACTIVITY,
  id
});

export const submitComment = payload => ({
  type: TYPES.SUBMIT_COMMENT,
  payload
});

export const deleteComment = id => ({
  type: TYPES.DELETE_COMMENT,
  id
});

export const like = id => ({
  type: TYPES.LIKE,
  id
});

export const unlike = id => ({
  type: TYPES.UNLIKE,
  id
});
export const likeComment = id => ({
  type: TYPES.LIKE_COMMENT,
  id
});

export const unlikeComment = id => ({
  type: TYPES.UNLIKE_COMMENT,
  id
});

export const submitStatus = (message, attachments) => ({
  type: TYPES.SUBMIT_STATUS,
  message,
  attachments
});

export const updateStatus = (id, message, attachments) => ({
  type: TYPES.UPDATE_STATUS,
  id,
  message,
  attachments
});

export const deleteActivity = id => ({
  type: TYPES.DELETE_ACTIVITY,
  id
});

export const pinActivity = id => ({
  type: TYPES.PIN_ACTIVITY,
  id
});

export const unpinActivity = id => ({
  type: TYPES.UNPIN_ACTIVITY,
  id
});
