import { put, all, takeLatest, call } from 'redux-saga/effects';
import * as TYPES from './types';
import { getApi } from '../../api';

function* fetchDiaries() {
  const api = getApi();
  const response = yield call(api.fetchDiaries);
  yield put({ type: TYPES.FETCH_DIARIES_SUCCESS, payload: response });
}

function* fetchDiariesListener() {
  yield takeLatest(TYPES.FETCH_DIARIES, fetchDiaries);
}

export default function* diariesSaga() {
  yield all([fetchDiariesListener()]);
}
