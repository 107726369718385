import React from 'react';
import { translate } from '../../utils';
import { Link } from 'react-router-dom';
import { IconProfile } from '../../components/icons';
import { BackButton } from './back-btn';

export default function({}) {
  return (
    <div className="content">
      <BackButton />
      <h2 className="heading">
        {translate('PORTAL_REGISTER_OR_LOGIN_HEADING')}
      </h2>
      <div
        className="text"
        dangerouslySetInnerHTML={{
          __html: translate('PORTAL_REGISTER_OR_LOGIN_TEXT')
        }}
      ></div>
      <br />
      <div className="c-nav">
        <Link
          to={`/portal/login/email`}
          className="c-nav__link"
          activeClassName="state-active"
        >
          <span className="c-nav__custom-icon">{'Start'}</span>
          <span className="c-nav__text">
            {translate('PORTAL_NAVIGATION_LOGIN_TEXT')}
          </span>
        </Link>
        <Link
          to={`/portal/register`}
          className="c-nav__link"
          activeClassName="state-active"
        >
          <span className="c-nav__icon">
            <IconProfile color="#FFF" size={42} />
          </span>
          <span className="c-nav__text">
            {translate('PORTAL_NAVIGATION_REGISTER_TEXT')}
          </span>
        </Link>
      </div>
    </div>
  );
}
