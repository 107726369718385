import {
  SET_WALKTHROUGH_STEP,
  START_WALKTHROUGH,
  STOP_WALKTHROUGH
} from './types';


const walkthrough = (state = { currentStep: 0, isRunning: false }, action) => {
  switch (action.type) {
    case SET_WALKTHROUGH_STEP:
      return {
        ...state,
        currentStep: action.currentStep
      }
    case START_WALKTHROUGH:
      return {
        ...state,
        isRunning: true,
        currentStep: 0
      }
    case STOP_WALKTHROUGH:
        return {
          ...state,
          isRunning: false,
          currentStep: 0
        }
    default:
      return state;
  }
};

export default {
  walkthrough
};
