import * as TYPES from './types';

export const updateFirstVisit = () => ({
  type: TYPES.UPDATE_INTRO,
  payload: {
    first_visit: false
  }
});

export const updateUserData = (payload) => {
  return {
    type: TYPES.PATCH_USER_DATA,
    payload
  }
};

export const fetchUserData = () => {
  return {
    type: TYPES.FETCH_USER_DATA
  }
}

export const userLogout = () => ({
  type: TYPES.USER_LOGOUT
});
