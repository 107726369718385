import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';
import { translate } from '../../utils';

class FormErrors extends React.Component {
  render() {
    const { errors } = this.props;
    if (!errors || !errors.non_field_errors) {
      return null;
    }
    return (
      <div className="form__errors">
        {errors.non_field_errors.map((error, index) => (
          <p
            key={index}
            dangerouslySetInnerHTML={{ __html: translate(error) }}
          />
        ))}
      </div>
    );
  }
}

FormErrors.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

export default FormErrors;
