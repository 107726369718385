import React from 'react';

import { translate } from '../../utils';
import ReinOnChair from '../../assets/img/rein-on-chair.svg';
import { BackButton } from './back-btn';


class Failed extends React.Component {
  render() {
    const {match: {params: {code}}} = this.props
    return (
      <div className="content">
        <BackButton />
        <h2 className="heading">Het is niet gelukt</h2>
        <div className="text">
          Momenteel kan je niet inloggen. Probeer het later nog eens of neem contact op met je organisatie.
        </div>
      </div>
    );
  }
}

export default Failed;
