import React from 'react';

import { translate } from '../../utils';
import ReinOnChair from '../../assets/img/rein-on-chair.svg';
import { BackButton } from './back-btn';


class About extends React.Component {
  render() {
    return (
      <div className="content">
        <BackButton />
        <h2 className="heading">{translate('PORTAL_ABOUT_HEADING')}</h2>
        <div className="text" dangerouslySetInnerHTML={{__html: translate('PORTAL_ABOUT_TEXT')}}></div>
      </div>
    );
  }
}

export default About;
