import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { IconDownload, IconNext, IconPrev } from '../../components/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Header from '../../components/header';
import classNames from 'classnames';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.css';
import { fetchDiaries } from '../../store/diaries/actions';
import { translate } from '../../utils';

class Diaries extends React.Component {
  state = {
    slideSelected: 0
  };

  componentDidMount() {
    if (!this.props.diariesFetched) {
      this.props.fetchDiaries();
    }
  }

  nextSlide() {
    this.slider.slickNext();
  }
  previousSlide() {
    this.slider.slickPrev();
  }

  render() {
    const { slideSelected } = this.state;
    const { diaries, diariesFetched } = this.props;
    const responsiveSettings = [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ];
    const settings = {
      adaptiveHeight: true,
      arrows: false,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: diaries.length >= 3 ? 3 : 1,
      slidesToScroll: 1,
      initialSlide: slideSelected,
      afterChange: index => {
        this.setState({ slideSelected: index });
      },
      responsive: responsiveSettings
    };
    if (!diariesFetched) {
      return null;
    }
    return (
      <section className="s-diaries">
        <Header {...this.props} />
        <div className="responsive-container">
          <div className="title-container">
            <h1 className="title">{translate('DIARIES_TITLE')}</h1>
          </div>
          <div className="slider-container">
            <span className={classNames({"arrow-prev": true, "hidden": diaries.length <= 1 })} onClick={this.previousSlide.bind(this)}>
              <IconPrev size={'10'} />
            </span>
            <Slider ref={c => (this.slider = c)} className="diary-slider" {...settings}>
              {diaries.map(
                ({
                  uuid,
                  content: { image, pdf },
                  meta: { media_url }
                }) => (
                    <div className="diary-slide" key={uuid}>
                      <Link to={`/diaries/${uuid}`}>
                        <img className="img" src={`${media_url}${image}`} />
                      </Link>
                      <a
                        className="icon"
                        href={`${media_url}${pdf}`}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <IconDownload size="18px" color="#fff" />
                      </a>
                    </div>
                  )
              )}
            </Slider>
           <span className={classNames({"arrow-next": true, "hidden": diaries.length <= 1 })} onClick={this.nextSlide.bind(this)}>
              <IconNext size={'10'} />
            </span>
          </div>
          {diaries.length > 0 && (
            <div className="diary-details">
              <div className="title-container">
                <h1 className="title">
                  {diaries[slideSelected].content.title}
                </h1>
              </div>
              <p className="desc">
                {diaries[slideSelected].content.excerpt}
              </p>
            </div>
          )}
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    diaries: state.diaries.results,
    diariesFetched: state.diaries.fetched
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDiaries
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Diaries);
