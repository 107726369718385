import React from 'react';
import linkifyHtml from 'linkifyjs/html';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { IconLike } from '../../components/icons';


import {
  deleteComment,
  likeComment,
  unlikeComment
} from '../../store/timeline/actions';
import { translate } from '../../utils';

class Comment extends React.Component {
  state = {};

  constructor(props) {
    super(props);
  }

  render() {
    const {
      userName,
      userImage,
      comment,
      likeComment,
      unlikeComment,
      deleteComment,
      likes,
      liked
    } = this.props;

    return (
      <div className="comment">
        <div className="comment__left">
          <img className="comment__image" src={userImage} />
          <div className="comment__content">
            <p className="comment__name">{userName}</p>
            <div
              className="comment__text"
              dangerouslySetInnerHTML={{ __html: linkifyHtml(comment) }}
            />
          </div>
        </div>
        <div className="comment__right">
          <p className="comment__likes">
            <IconLike className="comment__likes__icon" color={'#78B4B3'} size={'15'} />
            {likes.length} {translate('TIMELINE_LIKES')}
          </p>
          {liked ? (
            <button className="comment__btn-like" onClick={unlikeComment}>
              <IconLike color={'#78B4B3'} />
            </button>
          ) : (
              <button className="comment__btn-like" onClick={likeComment}>
                <IconLike color={'#fff'} />
              </button>
            )}
        </div>
      </div>
    );
  }
}

Comment.propTypes = {
  id: PropTypes.number,
  userImage: PropTypes.string,
  userName: PropTypes.string,
  comment: PropTypes.string,
  likeComment: PropTypes.func,
  unlikeComment: PropTypes.func,
  deleteComment: PropTypes.func,
  likes: PropTypes.array,
  liked: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => ({
  liked: !!ownProps.likes.find(
    ({ user: { id } }) => id == state.user.data.user.pk
  )
});

const mapDispatchToProps = (dispatch, { id }) =>
  bindActionCreators(
    {
      deleteComment: deleteComment.bind(null, id),
      likeComment: likeComment.bind(null, id),
      unlikeComment: unlikeComment.bind(null, id)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Comment);
