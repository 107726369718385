import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import './styles.css';

class Button extends React.Component {
  renderLink(link, inner) {
    const external = link && link.substr(0, 4) === 'http';
    return link && !external ? (
      <Link to={link}>{inner}</Link>
    ) : link && external ? (
      <a href={link}>{inner}</a>
    ) : (
      inner
    );
  }

  render() {
    const { className, text, icon, disabled, onClick, link } = this.props;

    return this.renderLink(
      link,
      <button
        className={classNames({
          'c-button': true,
          'state-disabled': disabled,
          [className]: className
        })}
        onClick={!disabled ? onClick : undefined}
        type={this.props.type}
        disabled={disabled}
      >
        {icon}
        {text}
      </button>
    );
  }
}

Button.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  icon: PropTypes.node,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default Button;
