import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from '../../utils';
import classNames from 'classnames';
import ReactPlayer from 'react-player';

import {
  fetchActivities,
  submitStatus,
  updateStatus
} from '../../store/timeline/actions';
import Activity from './activity';
import Header from '../../components/header';
import { IconSend, IconAttachment } from '../../components/icons';
import './styles.css';

class Timeline extends React.Component {
  state = { message: '' };
  fileUpload = React.createRef();

  componentDidMount() {
    if (!this.props.activities.length) {
      this.props.fetchActivities();
    } else {
      const { statusId } = this.props.match.params;
      if (statusId) {
        this.editStatus(statusId);
      }
    }
  }

  onStatusChange = e => {
    const message = e.target.value;
    this.setState({ message });
  };

  onSubmitStatus = e => {
    e.preventDefault();
    if (this.state.message) {
      if (this.state.id) {
        this.props.updateStatus(
          this.state.id,
          this.state.message,
          this.state.uploadFile
        );
        this.props.history.replace('/timeline');
      } else {
        this.props.submitStatus(this.state.message, this.state.uploadFile);
      }
    }
  };

  editStatus(statusId) {
    const activity = this.props.activities.find(a => a.content.id == statusId);
    this.setState({
      message: activity.content.message,
      id: activity.content.id
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { statusId } = this.props.match.params;
    if (
      statusId &&
      this.props.activities.length &&
      !prevProps.activities.length
    ) {
      this.editStatus(statusId);
    }
    if (prevProps.processing === true && this.props.processing === false) {
      this.setState({
        message: '',
        uploadFile: null,
        uploadFilePreviewUrl: null
      });
    }
  }

  onUploadFileChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        uploadFile: file,
        uploadFilePreviewUrl: reader.result
      });
    };

    reader.readAsDataURL(file);
    e.target.value = '';
  };

  removeUploadFile = e => {
    e.preventDefault();
    this.setState({
      uploadFile: null,
      uploadFilePreviewUrl: null
    });
    this.fileUpload.current.value = '';
  };

  renderPreview() {
    const { uploadFilePreviewUrl, uploadFile } = this.state;
    if (!uploadFilePreviewUrl) {
      return null;
    } else if (uploadFilePreviewUrl.startsWith('data:video')) {
      return (
        <div className="upload-preview">
          <ReactPlayer url={uploadFilePreviewUrl} width="100%" />
          <button onClick={this.removeUploadFile}>
            {translate('TIMELINE_CLICK_TO_DELETE')}
          </button>
        </div>
      );
    } else if (uploadFilePreviewUrl.startsWith('data:image')) {
      return (
        <div className="upload-preview">
          <img src={uploadFilePreviewUrl} />
          <button onClick={this.removeUploadFile}>
            {translate('TIMELINE_CLICK_TO_DELETE')}
          </button>
        </div>
      );
    } else {
      return (
        <div className="upload-preview pdf">
          <div className="message__content">
            <div className="file-preview">
              <IconAttachment color={'#555'} />
              <p>{uploadFile.name}</p>
            </div>
            <button onClick={this.removeUploadFile}>
              {translate('TIMELINE_CLICK_TO_DELETE')}
            </button>
          </div>
        </div>
      );
    }
  }

  render() {
    const { activities } = this.props;
    const { statusId } = this.props.match.params;

    return (
      <section className="s-timeline">
        <Header {...this.props} />
        <div className="responsive-container">
          <header className="section-header">
            <h2 className="header__heading">
              {translate('TIMELINE_HEADER_TEXT')}
            </h2>
          </header>
          <div className="messenger">
            <form onSubmit={this.onSubmitStatus}>
              {this.renderPreview()}
              <textarea
                value={this.state.message}
                onChange={this.onStatusChange}
                placeholder={translate('TIMELINE_MESSAGE_INPUT_PLACEHOLTER')}
                required
                className="messenger__input"
              />
              <div className="messenger__buttons">
                <label
                  className="messenger__btn file-upload"
                  disabled={this.props.processing}
                >
                  <input
                    ref={this.fileUpload}
                    type="file"
                    onChange={this.onUploadFileChange}
                    disabled={this.props.processing}
                  />
                  <IconAttachment color={'#fff'} />
                </label>
                <button
                  disabled={this.props.processing}
                  type="submit"
                  className="messenger__btn"
                >
                  <IconSend color={'#fff'} />
                </button>
              </div>
              {
                <div
                  className={classNames({
                    loader: true,
                    active: this.props.processing
                  })}
                />
              }
            </form>
          </div>

          {!statusId && !this.props.processing && (
            <div className="activities">
              {activities.length > 0 &&
                activities.map(activity => (
                  <Activity
                    history={this.props.history}
                    key={activity.id}
                    data={activity}
                  />
                ))}
            </div>
          )}
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  activities: state.timeline.activities,
  processing: state.timeline_processing.status
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchActivities,
      submitStatus,
      updateStatus
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Timeline);
