import '@babel/polyfill';
import './dynamic-public-path';
import React from 'react';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';
import { connect, Provider } from 'react-redux';
import smoothscroll from 'smoothscroll-polyfill';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Helmet } from 'react-helmet';

import { brandStyle } from './brand-config';
import { getApi } from './api';
import { rootElement } from './root';
import { store, history } from './store';
import App from './app';
import Portal from './containers/portal';
import './styles/index.css';
import { hot } from 'react-hot-loader/root';
import { CHECK_AUTHENTICATED } from './store/user/types';
import {SITE_URL} from "./variables";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

smoothscroll.polyfill();
brandStyle();

const next = window.location.href.split('?next=');
if (next.length === 2) {
  const nextUrl = decodeURIComponent(next[1]);
  window.localStorage.setItem('nextUrlBackend', nextUrl);
}

const helmet = (
  <Helmet>
    <link
      rel="stylesheet"
      href={
        'https://fonts.googleapis.com/css?family=Raleway:100,400,700,900&display=swap'
      }
    />
  </Helmet>
);

const Wrapper = connect(
  (state) => {
    return {
      authenticated: state.user.authenticated,
      translations: state.translations,
      fetchedUser: !!state.user.data,
    };
  },
  (dispatch) => ({
    checkAuthenticated: () => dispatch({ type: CHECK_AUTHENTICATED }),
  })
)(
  ({
    checkAuthenticated,
    authenticated,
    translations: { loaded },
    fetchedUser,
  }) =>
    loaded ? (
      <ConnectedRouter history={history}>
        <Switch>
          {!authenticated && (
            <Route
              path="/jwt/remote/:token"
              render={({ match }) => {
                const api = getApi();
                window.localStorage.setItem('nextUrl', '/');
                api.postLoginSSOJWT({ sso_jwt: match.params.token }).then((response) => {
                  fetch(`${SITE_URL}/api/accounts/refresh/`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      token: response.qollab_jwt
                    }),
                  }).then(response => response.json().then(tokens => {
                    api.setToken(tokens.token);
                    checkAuthenticated();
                  }))
                });
                return null;
              }}
            />
          )}
          {!authenticated && (
            <Route
              path="/jwt/:token"
              render={({ match }) => {
                const api = getApi();
                api.setToken(match.params.token);
                checkAuthenticated();
                return null;
              }}
            />
          )}
          <Redirect from="/failed/:code" to="/portal/failed/:code" />
          <Redirect from="/invite/code/:token" to="/portal/code/:token" />
          <Route path="/portal" component={Portal} />
          {authenticated && fetchedUser && <Route path="/" component={App} />}
          {!authenticated && <Redirect to="/portal" />}
        </Switch>
      </ConnectedRouter>
    ) : null
);

const Root = () => (
  <Provider store={store}>
    {helmet}
    <Wrapper />
  </Provider>
);

const RootElement = process.env.NODE_ENV === 'development' ? hot(Root) : Root;

getApi().apiPromise.then(() => {
  ReactDOM.render(<RootElement />, rootElement);
});
