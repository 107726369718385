import * as TYPES from './types';


const rapportage = (state = {}, action) => {
  switch(action.type) {
    case TYPES.RAPPORTAGE_STATUS:
      return {
        ...state,
        status: {
          allowed: action.payload.allowed,
          busy: action.payload.busy,
          organisations: action.payload.organisations
        }
      }
    default:
      return state;
  }
}


export default {
  rapportage
};