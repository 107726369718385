import React from 'react';

import './styles.css';


class Intro extends React.Component {
  render() {
    return (
      <section className="s-intro">
        <p>Intro</p>
      </section>
    );
  }
}

export default Intro;
