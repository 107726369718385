import { put, takeLatest, all } from 'redux-saga/effects';

import { getApi } from '../../api';
import * as TYPES from './types';

function* fetchActivities() {
  const api = getApi();
  try {
    const response = yield api.fetchTimelineActivities();
    yield put({ type: TYPES.FETCH_ACTIVITIES_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: TYPES.FETCH_ACTIVITIES_FAILED, error });
  }
}

function* fetchActivity(data) {
  const api = getApi();
  try {
    const response = yield api.fetchTimelineActivity(data.id);
    yield put({ type: TYPES.FETCH_ACTIVITY_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: TYPES.FETCH_ACTIVITY_FAILED, error });
  }
}

function* submitStatus(data) {
  const api = getApi();
  try {
    const response = yield api.submitStatus(data);
    yield put({ type: TYPES.SUBMIT_STATUS_SUCCESS, payload: response });
    yield fetchActivities();
  } catch (error) {
    yield put({ type: TYPES.SUBMIT_STATUS_FAILED, error });
  }
}

function* updateStatus(data) {
  const api = getApi();
  try {
    const response = yield api.updateStatus(data);
    yield put({ type: TYPES.UPDATE_STATUS_SUCCESS, payload: response });
    yield fetchActivities();
  } catch (error) {
    yield put({ type: TYPES.UPDATE_STATUS_FAILED, error });
  }
}


function* submitComment(data) {
  const api = getApi();
  try {
    const response = yield api.submitComment(data.payload);
    yield put({ type: TYPES.SUBMIT_COMMENT_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: TYPES.SUBMIT_COMMENT_FAILED, error });
  }
}
function* deleteComment(data) {
  const api = getApi();
  try {
    const response = yield api.deleteComment(data.id);
    yield put({ type: TYPES.DELETE_COMMENT_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: TYPES.DELETE_COMMENT_FAILED, error });
  }
}

function* likeActivity(data) {
  const api = getApi();
  try {
    const response = yield api.like(data.id);
    yield put({ type: TYPES.LIKE_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: TYPES.LIKE_FAILED, error });
  }
}
function* unlikeActivity(data) {
  const api = getApi();
  try {
    const response = yield api.unlike(data.id);
    yield put({ type: TYPES.UNLIKE_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: TYPES.UNLIKE_FAILED, error });
  }
}

function* likeComment(data) {
  const api = getApi();
  try {
    const response = yield api.likeComment(data.id);
    yield put({ type: TYPES.LIKE_COMMENT_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: TYPES.LIKE_COMMENT_FAILED, error });
  }
}
function* unlikeComment(data) {
  const api = getApi();
  try {
    const response = yield api.unlikeComment(data.id);
    yield put({ type: TYPES.UNLIKE_COMMENT_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: TYPES.UNLIKE_COMMENT_FAILED, error });
  }
}

function* deleteActivity(data) {
  const api = getApi();
  try {
    yield api.deleteActivity(data.id);
    yield put({ type: TYPES.DELETE_ACTIVITY_SUCCESS, id: data.id });
  } catch(error) {
    yield put({ type: TYPES.DELETE_ACTIVITY_FAILED, error });
  }
}

function* pinActivity(data) {
  const api = getApi();
  try {
    yield api.pinActivity(data.id);
    yield put({ type: TYPES.PIN_ACTIVITY_SUCCESS, id: data.id });
  } catch(error) {
    yield put({ type: TYPES.PIN_ACTIVITY_FAILED, error });
  }
}

function* unpinActivity(data) {
  const api = getApi();
  try {
    yield api.unpinActivity(data.id);
    yield put({ type: TYPES.UNPIN_ACTIVITY_SUCCESS, id: data.id });
  } catch(error) {
    yield put({ type: TYPES.UNPIN_ACTIVITY_FAILED, error });
  }
}

export function* fetchActivitiesListener() {
  yield takeLatest(TYPES.FETCH_ACTIVITIES, fetchActivities);
}

export function* fetchActivityListener() {
  yield takeLatest(TYPES.FETCH_ACTIVITY, fetchActivity);
}
export function* submitStatusListener() {
  yield takeLatest(TYPES.SUBMIT_STATUS, submitStatus);
}

export function* submitCommentListener() {
  yield takeLatest(TYPES.SUBMIT_COMMENT, submitComment);
}
export function* deleteCommentListener() {
  yield takeLatest(TYPES.DELETE_COMMENT, deleteComment);
}
export function* likeActivityListener() {
  yield takeLatest(TYPES.LIKE, likeActivity);
}
export function* unlikeActivityListener() {
  yield takeLatest(TYPES.UNLIKE, unlikeActivity);
}
export function* likeCommentListener() {
  yield takeLatest(TYPES.LIKE_COMMENT, likeComment);
}
export function* unlikeCommentListener() {
  yield takeLatest(TYPES.UNLIKE_COMMENT, unlikeComment);
}
export function* deleteActivityListener() {
  yield takeLatest(TYPES.DELETE_ACTIVITY, deleteActivity);
}
export function* pinActivityListener() {
  yield takeLatest(TYPES.PIN_ACTIVITY, pinActivity);
}
export function* unpinActivityListener() {
  yield takeLatest(TYPES.UNPIN_ACTIVITY, unpinActivity);
}

export default function* timelineSaga() {
  yield all([
    fetchActivitiesListener(),
    fetchActivityListener(),
    submitCommentListener(),
    deleteCommentListener(),
    likeActivityListener(),
    unlikeActivityListener(),
    likeCommentListener(),
    unlikeCommentListener(),
    submitStatusListener(),
    takeLatest(TYPES.UPDATE_STATUS, updateStatus),
    deleteActivityListener(),
    pinActivityListener(),
    unpinActivityListener(),
  ]);
}
