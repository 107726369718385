import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import Header from '../../components/header';
import Dropdown from '../../components/dropdown';
import FormField from '../../components/form_field';
import AccordionItem from '../../components/accordion_item';
import './styles.css';
import { translate } from '../../utils';
import Button from '../../components/button';
import { submitPEOnline } from '../../store/library/actions';
import { API_URL } from '../../variables';

class Library extends React.Component {
  static propTypes = {
    submitPEOnline: PropTypes.func,
    history: PropTypes.object,
    library: PropTypes.object,
    big_number: PropTypes.string,
    location: PropTypes.any,
  };

  state = {
    moduleFilter: {
      category: null,
      targetgroup: null,
      level: null,
      completion: null,
      query: null,
    },
  };
  componentDidMount() {
    this.updateModuleState();
    setTimeout(this.tryAndScroll.bind(this), 1500);
  }

  tryAndScroll() {
    if (this.props.location.hash && (this.state.modules || []).length) {
      const moduleUuid = this.props.location.hash.slice(1);
      const module = this.state.modules.find((m) => m.uuid == moduleUuid);
      if (module && module.ref.current) {
        module.ref.current.scrollIntoView({
          // behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }

  checkCompletionStatus(requiredStatus, started_at, completed_at){
    switch (requiredStatus){
      case 'not_attempted':
        return !started_at;
      case 'incomplete':
        return !!started_at && !completed_at
      case 'completed':
        return !!completed_at;
      default:
        return true
    }
  }

  updateModuleState() {
    const moduleUuid = this.props.location.hash.slice(1);
    const { modules } = this.props.library;
    const { moduleFilter } = this.state;

    const filteredModules = modules.filter(
      ({
        translatable_content: { category },
        title,
        description,
        completed_at,
        started_at
      }) => {
        const levelTargetgroupMatch =
          (moduleFilter.level || moduleFilter.targetgroup) &&
          title.match(/([A-Z][A-Z])(\d*)$/);
        const completionMatch =
          moduleFilter.completion
        return (
          (moduleFilter.category
            ? moduleFilter.category == category.trim()
            : true) &&
          (moduleFilter.level
            ? levelTargetgroupMatch &&
              levelTargetgroupMatch[2].includes(moduleFilter.level)
            : true) &&
          (moduleFilter.targetgroup
            ? levelTargetgroupMatch &&
              levelTargetgroupMatch[1].includes(moduleFilter.targetgroup)
            : true) &&
          (moduleFilter.completion
            // ? completed >= completionMatch[0] && completed <= completionMatch[1] //useless as completion is either 0 or 100
           ? completionMatch && this.checkCompletionStatus(completionMatch, started_at, completed_at) : true ) &&
          (moduleFilter.query
            ? title.toLowerCase().includes(moduleFilter.query.toLowerCase()) ||
              description
                .toLowerCase()
                .includes(moduleFilter.query.toLowerCase())
            : true)
        );
      }
    );
    this.setState({
      modules: Array.from(
        new Set(filteredModules.map((module) => module.uuid))
      ).map((uuid) => {
        const m = filteredModules.find((module) => module.uuid === uuid);
        return {
          ...m,
          ref: React.createRef(),
          open: moduleUuid == m.uuid,
        };
      }),
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.library != this.props.library) {
      this.updateModuleState();
    }
    if (prevState.modules != this.state.modules) {
      this.tryAndScroll();
    }
    if (prevState.moduleFilter !== this.state.moduleFilter) {
      this.updateModuleState();
    }
  }

  handleQueryChange = (e) => {
    this.setState({
      moduleFilter: {
        ...this.state.moduleFilter,
        query: e.target.value,
      },
    });
  };

  handleCategoryChange = (e) => {
    this.setState({
      moduleFilter: {
        ...this.state.moduleFilter,
        category: e.target.value,
      },
    });
  };
  handleLevelChange = (e) => {
    this.setState({
      moduleFilter: {
        ...this.state.moduleFilter,
        level: e.target.value,
      },
    });
  };
  handleTargetGroupChange = (e) => {
    this.setState({
      moduleFilter: {
        ...this.state.moduleFilter,
        targetgroup: e.target.value,
      },
    });
  };
  handleCompletionChange = (e) => {
    this.setState({
      moduleFilter: {
        ...this.state.moduleFilter,
        completion: e.target.value,
      },
    });
  };

  submitPEOnline = (uuid) => () => {
    const BIGNumber = this.props.big_number;
    if (!BIGNumber || (BIGNumber.length != 6 && BIGNumber.length != 11)) {
      if (window.confirm(translate('LIBRARY_ENTER_REGISTER_NUMBER'))) {
        this.props.history.push(
          '/profile?return_url=' +
            this.props.match.path +
            '&return_hash=' +
            uuid
        );
      }
    } else {
      this.props.submitPEOnline({ uuid, big_number: BIGNumber });
    }
  };

  render() {
    const { categories, loading } = this.props.library;
    const modules = this.state.modules || [];
    const levels = Array.from(Array(6).keys()).map((i) => ({
      label: i + 1,
      value: i + 1,
    }));
    return (
      <section className={classNames({ 's-library': true })}>
        <Header {...this.props} />
        <div className="responsive-container">
          <div className="filter-container">
            <Dropdown
              onChange={this.handleCategoryChange}
              items={[
                { label: translate('LIBRARY_CATEGORY_ALL'), value: '' },
                ...categories,
              ]}
            />

            <Dropdown
              onChange={this.handleLevelChange}
              items={[
                { label: translate('LIBRARY_LEVEL_ALL'), value: '' },
                ...levels,
              ]}
            />
          </div>
          <div className="filter-container">
            <Dropdown
              onChange={this.handleTargetGroupChange}
              items={[
                { label: translate('LIBRARY_TARGET_GROUP_ALL'), value: '' },
                { label: 'Verpleeg en Verzorgingshuis', value: 'VV' },
                { label: 'Thuiszorg', value: 'TZ' },
                { label: 'Ziekenhuis', value: 'ZH' },
                { label: 'Branche Neutraal', value: 'BN' },
              ]}
            />

            <Dropdown
              onChange={this.handleCompletionChange}
              items={[
                {
                  label: translate('LIBRARY_STATUS_ALL'),
                  value: 'all' },
                {
                  label: translate('LIBRARY_STATUS_NOT_ATTEMPTED'),
                  value: 'not_attempted'
                },
                {
                  label: translate('LIBRARY_STATUS_INCOMPLETE'),
                  value: 'incomplete',
                },
                {
                  label: translate('LIBRARY_STATUS_COMPLETED'),
                  value: 'completed',
                },
              ]}
            />
          </div>
          <div className="search-field">
            <FormField
              placeholder={translate('LIBRARY_FILTER_INPUT_TEXT')}
              onChange={this.handleQueryChange}
            />
          </div>
          <div className="libraries-container">
            <div className="header">
              <h2 className="title">{`${translate('LIBRARY_TITLE')}: ${modules.length}`}</h2>
            </div>
            {modules.map(
              ({
                title,
                description,
                completed_at,
                uuid,
                ref,
                open,
                started_at,
                invite_extra,
                translatable_content,
              }) => (
                <AccordionItem
                  history={this.props.history}
                  key={uuid}
                  innerRef={ref}
                  match={this.props.match}
                  uuid={uuid}
                  open={open}
                  title={title}
                  description={description}
                  started={!!started_at}
                  completed={!!completed_at}
                  theme={translatable_content.theme}
                  extraComponent={
                    !!completed_at && (
                      <>
                        <div className="extra-action">
                          <Button
                            link={`${API_URL}/api/customer_specific/rein/${uuid}/certificate/`}
                            text={translate('LIBRARY_DOWNLOAD_CERTIFICATE')}
                          />
                        </div>
                        {translatable_content['register-code'] &&
                          !invite_extra.pe_online && (
                            <div className="extra-action">
                              <Button
                                onClick={this.submitPEOnline(uuid)}
                                text={translate('LIBRARY_SUBMIT_TO_REGISTER')}
                              />
                            </div>
                          )}
                      </>
                    )
                  }
                />
              )
            )}
            {modules.length === 0 && !loading && (
              <div className="no-results-container">
                <p>{translate('LIBRARY_NO_RESULTS_TEXT')}</p>
              </div>
            )}
            {loading && (
              <div className="no-results-container">
                <p>{translate('LIBRARY_LOADING_TEXT')}</p>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  library: state.library,
  big_number:
    state.user.data &&
    state.user.data.profile_flexible_field &&
    state.user.data.profile_flexible_field.big_number,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ submitPEOnline }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Library);
