function get_subdomain_from_groupname(groupname) {
  return groupname.split('.')[0];
}

function get_studytube_login(
  groupname,
  path = '/my-learning/my-learnings/active'
) {
  const link = [
    'reinbijquarijn.studytube.nl',
    'reinbijwarande.studytube.nl',
  ].includes(groupname)
    ? `https://${groupname}${path}`
    : `https://login.studytube.nl/oauth/${get_subdomain_from_groupname(
        groupname
      )}/new`;
  return link;
}

export { get_studytube_login };
