import React, {createRef} from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateUserData } from '../../store/user/actions';
import { getApi } from '../../api';
import PropTypes from 'prop-types';

import FormField from '../form_field';
import Form from '../form/form';
import './styles.css';
import ChatMessage from '../chat_message';
import { translate } from '../../utils';

class Chat extends React.Component {
  static propTypes = {
    updateUserData: PropTypes.func,
    script: PropTypes.array,
    userData: PropTypes.object
  };
  state = {
    script: [],
    scriptRendered: [],
    input: '',
    isInputEnable: false
  };

  constructor(props) {
    super(props);
    this.state.script = this.props.script;
    this.scrollReference = React.createRef();
    this.inputRef = createRef(null)
  }

  async componentDidMount() {
    this.scrollReference.current.scrollIntoView();
    this.runMessages();
  }

  scrollToBottom = () => {
    this.scrollReference.current && this.scrollReference.current.scrollIntoView({ behavior: 'smooth' });
  };

  runMessages() {
    const scriptRendered = this.state.scriptRendered;

    const { userData } = this.props;
    let script = this.state.script;
    if (this.props.script == this.state.script) {
      let i = 0;
      let index = 0;
      let atStart = true;
      while (i < script.length) {
        const item = script[i];
        const notFilled =
          item.needUserInput &&
          (item.isFlexibleField
            ? !userData.profile_flexible_field || !userData.profile_flexible_field[item.APIidentifier]
            : !(['first_name', 'last_name'].includes(item.APIidentifier) ? userData.user : userData)[
                item.APIidentifier
              ]);
        if (notFilled) {
          index = atStart ? 0 : i;
          break;
        } else if (item.needUserInput) {
          atStart = false;
        }
        index = i;
        i++;
      }
      script = script.slice(index);
      this.setState({ script });
    }
    if (script.length == 0) {
      return;
    }
    const item = script[0];
    scriptRendered.push(item);
    this.setState(prevState => ({
      script: prevState.script.filter(message => message !== item)
    }));

    let halt = false;

    if (item.needUserInput) {
      this.setState({ isInputEnable: true });
      halt = true;
    } else {
      this.setState({ isInputEnable: false });
    }

    this.setState({ scriptRendered });
    this.scrollToBottom();
    setTimeout(this.scrollToBottom, 1050);
    if (!halt) {
      setTimeout(this.runMessages.bind(this), 3000);
    }
  }

  onUserMessageSend = async () => {
    const lastQuestion = this.state.scriptRendered[this.state.scriptRendered.length - 1];
    const { APIidentifier, isFlexibleField, encode, choices } = lastQuestion;
    let input = this.state.input || lastQuestion.defaultValue || null
    if (!input) {
       return false
    }
    if(APIidentifier === 'big_number' && input.length === 11) {
      let big_number = input
      const  { valid } = await getApi().validateBigRegistration(big_number);
      if(!valid) {
        if(this.inputRef && this.inputRef.current){
          this.inputRef.current.setCustomValidity(translate('CHAT_BIG_NUMBER_INVALID_MESSAGE'))
          this.inputRef.current.reportValidity()
          this.inputRef.current && this.inputRef.current.focus();
        }
        return false;
      }
      // is valid, skip 1
      let script = [...this.state.script];
      script = script.slice(1);
      this.setState({script});
    }
    input = encode ? encode(input) : input;
    if(APIidentifier === 'has_big_vvn_registration' && input === 'false'){
      this.props.updateUserData({
        profile_flexible_field: {
          'has_big_vvn_registration': input,
          'big_number': 'x'
        }
      });
      // skip big_number and follow up message
      let script = [...this.state.script];
      script = script.slice(2);
      this.setState(prevState => {
        const fb = choices && choices.length ? translate(choices.find(c => c.value === input).label) : input
        return {
          scriptRendered: [
            ...prevState.scriptRendered,
            {
              message: translate(fb),
              type: 'user'
            }
          ],
          script,
          input: ''
        }
      }, () => {
        this.runMessages();
      });
    } else {
      isFlexibleField ? this.updateUserDataFlexible(APIidentifier, input) : this.updateUserData(APIidentifier, input);
      const fb = choices && choices.length ? translate(choices.find(c => c.value === input).label) : input
      this.setState(prevState => {
        return {
          scriptRendered: [
            ...prevState.scriptRendered,
            {
              message: fb,
              type: 'user'
            }
          ],
          input: ''
        };
      },  () => {
        this.runMessages()
      });
    }
  };

  updateUserDataFlexible = (APIidentifier, value, other) => {
    this.props.updateUserData({
      profile_flexible_field: Object.assign({
        [APIidentifier]: value
      }, other || {})
    });
  };

  updateUserData = (APIidentifier, value, other) => {
    this.props.updateUserData(Object.assign({
      [APIidentifier]: value,
    }, other || {}));
  };

  onMultipleOptionClicked = async e => {
    const optionValue = e.currentTarget.dataset.value;
    const lastQuestion = this.state.scriptRendered[this.state.scriptRendered.length - 1];
    const { APIidentifier, isFlexibleField, isChatFinished } = lastQuestion;
    if (isChatFinished) {
      this.updateUserDataFlexible(APIidentifier, true, {
        "chat_finished_at": new Date().toISOString()
      });
      return;
    }
    isFlexibleField
      ? this.updateUserDataFlexible(APIidentifier, optionValue)
      : this.updateUserData(APIidentifier, optionValue);

    await this.setState(prevState => {
      return {
        scriptRendered: [
          ...prevState.scriptRendered,
          {
            message: translate(optionValue),
            type: 'user'
          }
        ]
      };
    });
    this.runMessages();
  };

  render() {
    const { scriptRendered, isInputEnable } = this.state;
    const lastQuestion = scriptRendered[scriptRendered.length - 1];
    const {
      choices = [],
      defaultValue,
      format,
      required,
      min,
      max,
      maxLength,
      pattern,
      title
    } = lastQuestion || {};
    return (
      <div className="c-chat">
        <div className="chat-container">
          <div className="date">{'Vandaag'}</div>
          {scriptRendered.map(({ message, type }, index) => (
            <ChatMessage key={index} message={message} type={type} />
          ))}
          <div ref={this.scrollReference} />
        </div>
        <div
          className={classNames({
            'bottom-container': true,
            disabled: !isInputEnable
          })}
        >
          {choices.length === 0 && (
            <Form
              onSubmit={this.onUserMessageSend.bind(this)}
              buttonText={translate('CHAT_SUBMIT_BUTTON_TEXT')}
              className={'chat-input-group'}
            >
              <FormField
                inputref={this.inputRef}
                type={format ? format : 'text'}
                name="chat-input"
                placeholder={translate('CHAT_INPUT_PLACEHOLDER_TEXT')}
                required={required}
                min={min}
                max={max}
                maxLength={maxLength}
                value={
                  this.state.input.length > 0
                    ? this.state.input
                    : defaultValue || ''
                  }
                pattern={pattern}
                title={title ? translate(title) : ''}
                onChange={e => {
                  this.setState({ input: e.target.value });
                }}
              />
            </Form>
          )}
          {choices.length > 0 && format === 'select' && (
            <Form
              onSubmit={this.onUserMessageSend.bind(this)}
              buttonText={translate('CHAT_SUBMIT_BUTTON_TEXT')}
              className={'chat-input-group'}
            >
              <select
                className="c-form-field"
                name="chat-input"
                placeholder={translate('CHAT_INPUT_PLACEHOLDER_TEXT')}
                required={required}
                min={min}
                max={max}
                value={
                  this.state.input.length > 0
                    ? this.state.input
                    : defaultValue || ''
                  }
                pattern={pattern}
                title={title ? translate(title) : ''}
                onChange={e => {
                  this.setState({ input: e.target.value });
                }}
              >
                {choices.map(
                  (choice, i) => (
                    typeof choice === 'object'
                      ? <option key={i} value={choice['value']}>{translate(choice['label'])}</option>
                      : <option key={i} value={choice}>{translate(choice)}</option>
                  )
                )}
              </select>
            </Form>
          )}
          {choices.length > 0 && format !== 'select' && (
            <div className="multi-options-container">
              {choices.map((choice, index) => {
                return (
                  <span key={index} className="option" data-value={choice} onClick={this.onMultipleOptionClicked}>
                    {translate(choice)}
                  </span>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userData: window && window.newborn
      ? { profile_flexible_field: {}, user: {} }
      : state.user.data
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({ updateUserData }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Chat);
