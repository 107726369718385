import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Intro from './containers/intro';
import Library from './containers/library';
import Diaries from './containers/diaries';
import Timeline from './containers/timeline';
import Profile from './containers/profile';
import Dashboard from './containers/dashboard';
import DiaryDetails from './containers/diary-details';
import AskMe from './containers/ask-me';
import Entree from './containers/entree';
import LibraryModule from './containers/library-module';
import ChatRoom from './containers/chat-room';
import Rapportage from './containers/rapportage';

class App extends React.Component {
  render() {
    const {
      location,
      walkthroughFinished,
      chatFinished,
      learnlinq,
    } = this.props;

    return (
      <div className="s-app">
        <TransitionGroup component={null}>
          <CSSTransition
            classNames="viewswitch"
            timeout={800}
            key={location.pathname}
          >
            <Switch location={location}>
              {!chatFinished && (
                <Route exact={true} path="/chat" component={ChatRoom} />
              )}
              {!chatFinished && <Redirect to="/chat" />}
              <Route exact={true} path="/" component={Dashboard} />
              {!walkthroughFinished && <Redirect to="/" />}
              <Route exact={true} path="/intro" component={Intro} />
              <Route exact={true} path="/library" component={Library} />
              <Route
                exact={true}
                path="/library/:uuid"
                component={LibraryModule}
              />
              <Route exact={true} path="/diaries" component={Diaries} />
              <Route
                exact={true}
                path="/diaries/:uid"
                component={DiaryDetails}
              />
              <Route exact={true} path="/timeline" component={Timeline} />
              <Route
                exact={true}
                path="/timeline/:statusId"
                component={Timeline}
              />
              <Route exact={true} path="/profile" component={Profile} />
              <Route exact={true} path="/ask-me" component={AskMe} />
              <Route exact={true} path="/entree/:area" component={Entree} />
              <Route exact={true} path="/rapportage" component={Rapportage} />
              <Redirect to="/" />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </div>
    );
  }
}

App.propTypes = {
  location: PropTypes.object,
};

const mapStateToProps = (state) => {
  const hasFlex = state.user.data.profile_flexible_field;
  return {
    learnlinq: state.user.learnlinq || state.user.studytube,
    translations: state.translations,
    walkthroughFinished:
      hasFlex && state.user.data.profile_flexible_field.walkthrough_done,
    chatFinished:
      window && window.newborn
        ? false
        : hasFlex && state.user.data.profile_flexible_field.chat_done,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
