import cssVars from 'css-vars-ponyfill';

export const brandStyle = () => {
  const style = ({
    bodyFont = 'Raleway, sans-serif',
    bodyWeight = 'normal',
    headingFont = 'Raleway, sans-serif',
    headingWeight = 'bold',
    color1 = '#78B4B3',
    bodyColor = '#555',
  }) => {
    return cssVars({
      onlyVars: true,
      variables: {
        // user customizable styles
        '--body-font': bodyFont,
        '--body-weight': bodyWeight,
        '--heading-font': headingFont,
        '--heading-weight': headingWeight,
        '--color-1': color1,

        // developer convenient variables
        '--body-color': bodyColor,
      },
    });
  }

  let styling = style({});
  return styling;
}
