/* eslint react/prop-types: 0 */
import React from 'react';


export const Logo = ({ className = '', size = '214', color = '#79B7B6' }) => (
  <svg className={className} width={size} height={parseInt(size) - 126} viewBox="0 0 214 88" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.659,86.891 L0.565,29.302 L27.007,25.586 C29.6986685,25.1813558 32.4474245,25.4038025 35.039,26.236 C37.5059444,27.0232994 39.8006954,28.2724828 41.801,29.917 C43.7763252,31.5549746 45.4414224,33.5343945 46.717,35.761 C47.992685,37.92954 48.834953,40.325249 49.197,42.815 C49.6776345,46.0639907 49.3780455,49.3807221 48.323,52.491 C47.3134597,55.5222401 45.5660361,58.2548876 43.238,60.443 L58.379,79.903 L40.535,82.411 L27.911,66.154 L21.99,66.986 L24.475,84.668 L8.659,86.891 Z M20.052,53.197 L30.029,51.797 C31.1296379,51.5643528 32.0538301,50.8217315 32.518,49.797 C33.2430421,48.3365048 33.4608431,46.6760795 33.137,45.078 C33.0194418,43.4046528 32.2684624,41.8390396 31.037,40.7 C30.2020926,39.8833716 29.060723,39.4579916 27.895,39.529 L18.324,40.874 L20.052,53.197 Z" id="Path_8"></path>
    <polygon id="Path_9" points="110.622 58.5 112.56 72.288 71.518 78.056 63.425 20.465 103.737 14.799 105.675 28.588 81.175 32.031 82.315 40.142 103.242 37.201 105.042 50.016 84.12 52.965 85.397 62.05"></polygon>
    <polygon id="Path_10" points="128.051 70.111 119.958 12.523 135.774 10.3 143.868 67.888"></polygon>
    <polygon id="Path_11" points="173.711 34.828 177.689 63.135 161.873 65.358 153.784 7.765 166.113 6.033 193.34 32.065 189.225 2.784 205.041 0.565 213.135 58.154 200.563 59.92"></polygon>
</svg>
);
