import React from 'react';
import { translate } from '../../utils';

class ChatMessage extends React.Component {
  state = {
    isBotTyping: true
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isBotTyping: false });
    }, 2000);
  }

  render() {
    const { type, message } = this.props;
    const { isBotTyping } = this.state;
    return (
      <>
        {type === 'user' ? (
          <div className="user-message">{message}</div>
        ) : isBotTyping ? (
          <div className="bot-typing-message">{translate('CHAT_BOT_TYPING_MESSAGE')}</div>
        ) : (
          <div className="bot-message">{translate(message)}</div>
        )}
      </>
    );
  }
}

export default ChatMessage;
